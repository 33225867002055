import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./ArticleListing.scss";
const ArticleListing = ({ articleId }) => {
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const navigate = useNavigate();
  // const REACT_APP_DEV_ENDPOINT = process.env.REACT_APP_DEV_ENDPOINT;
  const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const url = `${REACT_APP_ENDPOINT}article?language=${language}&status=1&catid=${articleId}&mobile=true`;

  const { data: articleList } = useQuery([`articleList`, articleId], () =>
    axios.get(url).then((data) => data.data.data)
  );

  return (
    <div className="mt-4">
      <h3 className="fw-bold" style={{ color: "#f16334" }}>
        Articles
      </h3>
      <div className="row g-3">
        {articleList?.length > 0 ? (
          articleList.map((article, index) => (
            <button
              onClick={() => navigate(`/article/${article._id}`)}
              key={index}
              className="col-4 article-card-button"
            >
              <div className="article-card shadow">
                <img
                  alt="article"
                  src={article.image}
                  className="article-card-img"
                />
                <div
                  className="article-title-div"
                  style={{ backgroundColor: "#f16334" }}
                >
                  <h5 className="article-title fw-semibold text-white">
                    {article.name}
                  </h5>
                </div>
              </div>
            </button>
          ))
        ) : (
          <div>
            <h2 style={{ color: "#f16334" }} className="fw-bold">
              No articles Found
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleListing;
