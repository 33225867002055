import React, { useState } from "react";
import MatchAshtakootPoints from "./MatchAshtakootPoints";
import MatchBirthDetails from "./MatchBirthDetails";
import MatchObstructons from "./MatchObstructons";
import MatchAstroDetails from "./MatchAstroDetails";
import MatchManglikReport from "./MatchManglikReport";
import MatchMakingReport from "./MatchMakingReport";
import MatchSimpleReport from "./MatchSimpleReport";
import MatchDetailedReport from "./MatchDetailedReport";
import MatchDashakootPoints from "./MatchDashakootPoints";
import MatchPercentage from "./MatchPercentage";
import PapasamyamDetails from "./PapasamyamDetails";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MatchMakingButton.scss";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const MatchMakingButtons = () => {
  const { t } = useTranslation();
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaArrowCircleLeft
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "23px",
          width: "23px",
        }}
        onClick={onClick}
      />
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaArrowCircleRight
        className={className}
        style={{ ...style, color: "#483f50", height: "23px", width: "23px" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  const [buttonName, setButtonName] = useState("matchBirthDetails");
  const [matchBirthDetailsClicked, setMatchBirthDetailsClicked] =
    useState(true);
  const [matchAshtakootPointsClicked, setMatchAshtakootPointsClicked] =
    useState(false);
  const [matchObstructionsClicked, setMatchObstructionsClicked] =
    useState(false);
  const [matchAstroDetailsClicked, setMatchAstroDetailsClicked] =
    useState(false);
  const [matchManglikReportClicked, setMatchManglikReportClicked] =
    useState(false);
  const [matchMakingReportClicked, setMatchMakingReportClicked] =
    useState(false);
  const [matchSimpleReportClicked, setMatchSimpleReportClicked] =
    useState(false);
  const [matchDetailedReportClicked, setMatchDetailedReportClicked] =
    useState(false);
  const [matchDashakootPointsClicked, setMatchDashakootPointsClicked] =
    useState(false);
  const [matchPercentageClicked, setMatchPercentageClicked] = useState(false);
  const [papasamyamDetailsClicked, setPapasamyamDetailsClicked] =
    useState(false);

  const handleButtons = (setButtonClicked, buttonName) => {
    setMatchBirthDetailsClicked(false);
    setMatchAshtakootPointsClicked(false);
    setMatchObstructionsClicked(false);
    setMatchAstroDetailsClicked(false);
    setMatchManglikReportClicked(false);
    setMatchMakingReportClicked(false);
    setMatchSimpleReportClicked(false);
    setMatchDetailedReportClicked(false);
    setMatchDashakootPointsClicked(false);
    setMatchPercentageClicked(false);
    setPapasamyamDetailsClicked(false);
    setButtonClicked(true);
    setButtonName(buttonName);
  };
  return (
    <div>
      <Slider {...settings}>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchBirthDetailsClicked, "matchBirthDetails")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchBirthDetails" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Birth Details")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(
              setMatchAshtakootPointsClicked,
              "matchAshtakootPoints"
            )
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchAshtakootPoints" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Ashtakoot Points")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchObstructionsClicked, "matchObstructions")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchObstructions" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Obstructions")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchAstroDetailsClicked, "matchAstroDetails")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchAstroDetails" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Astro Details")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchManglikReportClicked, "matchManglikReport")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchManglikReport" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Manglik Report")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchMakingReportClicked, "matchMakingReport")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchMakingReport" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Making Report")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchSimpleReportClicked, "matchSimpleReport")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchSimpleReport" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Simple Report")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchDetailedReportClicked, "matchDetailedReport")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchDetailedReport" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Detailed Report")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(
              setMatchDashakootPointsClicked,
              "matchDashakootPoints"
            )
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchDashakootPoints" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Dashakoot Points")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setMatchPercentageClicked, "matchPercentage")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "matchPercentage" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Match Percentage")}</small>
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setPapasamyamDetailsClicked, "papasamyamDetails")
          }
          className={`matchMaking-button w-100 ${
            buttonName === "papasamyamDetails" ? "clicked" : "not-clicked"
          }`}
        >
          <small>{t("Papasamyam Details")}</small>
        </button>
      </Slider>
      <div className="pt-3 pt-lg-5">
        {matchBirthDetailsClicked && <MatchBirthDetails />}
        {matchAshtakootPointsClicked && <MatchAshtakootPoints />}
        {matchObstructionsClicked && <MatchObstructons />}
        {matchAstroDetailsClicked && <MatchAstroDetails />}
        {matchManglikReportClicked && <MatchManglikReport />}
        {matchMakingReportClicked && <MatchMakingReport />}
        {matchSimpleReportClicked && <MatchSimpleReport />}
        {matchDetailedReportClicked && <MatchDetailedReport />}
        {matchDashakootPointsClicked && <MatchDashakootPoints />}
        {matchPercentageClicked && <MatchPercentage />}
        {papasamyamDetailsClicked && <PapasamyamDetails />}
      </div>
    </div>
  );
};

export default MatchMakingButtons;
