import React from "react";
import { useState } from "react";
import ArticleListing from "./ArticleListing";
import ArticlesCategories from "./ArticlesCategories";

const Articles = () => {
  const [articleId, setArticleId] = useState("");
  const handleArticleId = (id) => {
    // console.log(id);
    setArticleId(id);
  };
  return (
    <div
      style={{ paddingTop: "5.3%", margin: "20px" }}
      className="w-75 mx-auto"
    >
      <ArticlesCategories handleArticleId={handleArticleId} />
      <ArticleListing articleId={articleId} />
    </div>
  );
};

export default Articles;
