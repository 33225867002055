import { Tab, Tabs } from "react-bootstrap";

export default function ProfileTabs(props) {
  return (
    <div className="profile-nav">
      <Tabs
        className={"nav nav-tabs collapse-menus"}
        defaultActiveKey={0}
        activeKey={props?.activeTab}
        onSelect={(k) => props.selectTab(k)}
      >
        {props?.tabItem?.map((title, index) => (
          <Tab eventKey={index} title={title} key={index}></Tab>
        ))}
      </Tabs>
    </div>
  );
}
