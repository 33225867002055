import React, { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const PanchakaAndLagna = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center details-header">
        {t("Panchaka Rahita Muhurta")} {t("and")} {t("Udaya Lagna Muhurta")}
      </div>
      <div className="details-body panchang-gradient-5">
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Panchaka */}
          <div className="col-6">
            {panchangDetails?.Panchaka ? (
              <div>
                {panchangDetails?.Panchaka?.map((details, index) => (
                  <Fragment key={index}>
                    <h6 className="montserrat-font text-white">
                      {details?.upto}
                    </h6>
                    {details?.values.map((value, index) => (
                      <p key={index} className="montserrat-font fw-semibold">
                        {value}
                      </p>
                    ))}
                  </Fragment>
                ))}
              </div>
            ) : (
              <>
                <p className="montserrat-font text-white text-center">
                  {t("Panchaka Rahita Muhurta")}
                </p>
                <p className="montserrat-font fw-semibold text-center">None</p>
              </>
            )}
          </div>
          {/* Lagna */}
          <div className="col-6">
            {panchangDetails?.lagna ? (
              <div>
                {panchangDetails?.lagna?.map((details, index) => (
                  <Fragment key={index}>
                    <h6 className="montserrat-font text-white">
                      {details?.upto}
                    </h6>
                    {details?.values.map((value, index) => (
                      <p key={index} className="montserrat-font fw-semibold">
                        {value}
                      </p>
                    ))}
                  </Fragment>
                ))}
              </div>
            ) : (
              <>
                <p className="montserrat-font text-white text-center">
                  {t("Udaya Lagna Muhurta")}
                </p>
                <p className="montserrat-font fw-semibold text-center">None</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanchakaAndLagna;
