import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const AuspiciousTimings = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center details-header">
        {t("Auspicious Timings")}
      </div>
      <div className="details-body panchang-gradient-5">
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Brahma Muhurta */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">
                {t("Brahma Muhurta")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Brahma Muhurta"]
                  ? panchangDetails?.["Brahma Muhurta"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Pratah Sandhya */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Pratah Sandhya")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Pratah Sandhya"]
                  ? panchangDetails?.["Pratah Sandhya"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Abhijit */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Abhijit")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Abhijit"]
                  ? panchangDetails?.["Abhijit"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Vijaya Muhurta */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Vijaya Muhurta")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Vijaya Muhurta"]
                  ? panchangDetails?.["Vijaya Muhurta"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Godhuli Muhurta */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Godhuli Muhurta")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Godhuli Muhurta"]
                  ? panchangDetails?.["Godhuli Muhurta"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Sayahna Sandhya */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">
                {t("Sayahna Sandhya")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Sayahna Sandhya"]
                  ? panchangDetails?.["Sayahna Sandhya"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Amrit Kalam */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Amrit Kalam")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Amrit Kalam"]
                  ? panchangDetails?.["Amrit Kalam"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Nishita Muhurta */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Nishita Muhurta")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Nishita Muhurta"]
                  ? panchangDetails?.["Nishita Muhurta"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuspiciousTimings;
