import React from "react";
// import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Panchang.scss";

import { ReactComponent as LeftArrow } from "../../img/icons/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../img/icons/arrow-right.svg";
import { ReactComponent as CalendarIcon } from "../../img/icons/calendar.svg";
// import { useTranslation } from "react-i18next";

const ChooseDate = ({
  startDate,
  setStartDate,
  handlePreviousDay,
  handleToday,
  handleNextDay,
}) => {
  // const prevDay = moment(startDate).subtract(1, "day").format("MMM Do");
  // const nextDay = moment(startDate).add(1, "day").format("MMM Do");
  // const { t } = useTranslation();
  return (
    <div className="my-4">
      <div>
        <div className="d-flex gap-3 gap-lg-0 justify-content-between align-items-center">
          <div className="d-flex gap-1 gap-md-3 gap-lg-3">
            <button
              type="button"
              onClick={handlePreviousDay}
              className="arrow-button"
            >
              <LeftArrow style={{ height: "2.2vw", width: "2.2vw" }} />
            </button>
            <button
              type="button"
              onClick={handleToday}
              className="today-button montserrat-font"
            >
              Today
            </button>
            <button
              type="button"
              onClick={handleNextDay}
              className="arrow-button"
            >
              <RightArrow style={{ height: "2.2vw", width: "2.2vw" }} />
            </button>
          </div>
          <div className="d-flex select-date">
            <DatePicker
              className="date-picker"
              selected={startDate}
              dateFormat="dd-MM-yyyy"
              onChange={(date) => setStartDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {/* <div className="calendar-icon"> */}
            <CalendarIcon style={{ height: "40px", width: "40px" }} />
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseDate;
