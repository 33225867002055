import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeButtons = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-lg-row gap-2 gap-md-4 gap-lg-4 pb-3 pb-lg-5">
      <button
        onClick={() => navigate("/horoscope")}
        className="home-button w-100 montserrat-font text-white home-horoscope-button"
      >
        <span> {t("Horoscope")}</span>
      </button>
      <button
        onClick={() => navigate("/panchang")}
        className="home-button w-100 montserrat-font text-white home-panchang-button"
      >
        <span> {t("Panchang")}</span>
      </button>
      <button
        onClick={() => navigate("/kundli")}
        className="home-button w-100 montserrat-font text-white home-kundli-button"
      >
        <span>{t("Kundli")}</span>
      </button>
      <button
        onClick={() => navigate("/event")}
        className="home-button w-100 montserrat-font text-white home-event-button"
      >
        <span>{t("Browse Events")}</span>
      </button>
    </div>
  );
};

export default HomeButtons;
