import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import "./SingleArticle.scss";
const SingleArticle = () => {
  const { id } = useParams();
  // const REACT_APP_DEV_ENDPOINT = process.env.REACT_APP_DEV_ENDPOINT;REACT_APP_ENDPOINT
  const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const url = `${REACT_APP_ENDPOINT}article/single/${id}`;

  const { data: article } = useQuery([`articleList`, id], () =>
    axios.get(url).then((data) => data.data.data)
  );

  return (
    <div
      style={{ paddingTop: "5.3%", margin: "20px" }}
      className="w-75 mx-auto"
    >
      {article && (
        <div>
          <h3
            style={{
              color: "#f16334",
              fontWeight: 600,
            }}
          >
            {article?.name}
          </h3>
          <div className="article-img-div mx-auto shadow m-3">
            <img className="img-fluid" src={article?.image} alt="" />
          </div>
          {article?.publishDate && (
            <div className="card-body py-2 ">
              <h5
                className="card-title"
                style={{ color: "#f16334", fontWeight: 600 }}
              >
                Published at:{" "}
                <span>
                  {article.publishDate.split("T").join(" ").slice(0, -5)}
                </span>
              </h5>
            </div>
          )}
          <div className="card my-3 py-2 shadow-lg border-0">
            <div className="card-body py-2 ">
              {/* <h5
                className="card-title"
                style={{ color: "#f16334", fontWeight: 600 }}
              >
                Description
              </h5> */}
              <span>{parse(article?.description)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleArticle;
