import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { HoroscopeContext } from "./Horoscope";

const HoroscopeSigns = ({ handleRashi }) => {
  const { horoscopePeriod, handleHoroscopePeriod } =
    useContext(HoroscopeContext);

  const [signs, setSigns] = useState([]);
  const rashi = localStorage.getItem("rashi");
  const { t } = useTranslation();

  useEffect(() => {
    axios.get("jsons/horoscope.json").then((data) => setSections(data.data));
  }, []);

  useEffect(() => {
    axios.get("jsons/signs.json").then((data) => setSigns(data.data));
  }, []);
  const [sections, setSections] = useState([]);

  return (
    <div className="d-flex flex-column flex-md-row flex-lg-row gap-3 gap-lg-5 justify-content-center align-items-center">
      <div>
        <h6
          className="montserrat-font text-md-center text-lg-center"
          style={{ color: "#828282" }}
        >
          Choose an option
        </h6>
        <div className="d-flex flex-row flex-md-column flex-lg-column">
          {sections.map((section, index) => (
            <button
              key={index}
              // className="col-lg-2 col-md-4 col-sm-6 mx-auto d-flex flex-column justify-content-center align-items-center bg-transparent border border-0 rounded-3 p-2"
              className={`${
                horoscopePeriod === section.horoscopePeriod
                  ? "horoscope-period"
                  : "not-horoscope-period"
              }`}
              onClick={() => handleHoroscopePeriod(section.horoscopePeriod)}
            >
              <small
                style={{
                  color:
                    horoscopePeriod === section.horoscopePeriod
                      ? `white`
                      : "black",
                  fontSize: "2vw",
                }}
                className="montserrat-font"
              >
                {t(`${section.horoscopePeriod}`)}
              </small>
            </button>
          ))}
        </div>
      </div>
      <div style={{ maxWidth: "80%" }}>
        <div className="mb-3 mb-lg-5">
          <h6 className="poppins-font" style={{ color: "#EB7625" }}>
            {t(`${rashi}`)}
          </h6>
          <h6 className="montserrat-font" style={{ color: "#828282" }}>
            {t("Choose an option")}
          </h6>
        </div>
        <div className="row g-4">
          {signs.map((sign, index) => (
            <Fragment key={index}>
              <button
                className={`col-3 bg-transparent mx-auto border-0`}
                onClick={() => handleRashi(sign?.name.toUpperCase())}
              >
                <img
                  className={` ${
                    rashi === sign?.name.toUpperCase()
                      ? "horoscope-sign-clicked"
                      : "horoscope-sign-not-clicked"
                  }`}
                  src={sign?.image}
                  alt=""
                />
              </button>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HoroscopeSigns;
