import React from "react";
import sliderImg from "../../img/home/slider.png";
const HomeSlider = () => {
  return (
    <div className="h-5">
      <img src={sliderImg} className="img-fluid pt-5 pb-3 pb-lg-5" alt="" />
    </div>
  );
};

export default HomeSlider;
