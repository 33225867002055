import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomePanchang = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const date = moment(new Date()).format("DD/MM/YY");
  // const app_language = localStorage.getItem("REACT_APP_LANGUAGE") || "en";
  const app_language = "en";
  const data_language = i18n.language;

  const url = `https://panchang-bharat-calendar.onrender.com/day?date=${date}&app_language=${app_language}&data_language=${data_language}`;

  const { data: panchangDetails } = useQuery(
    [`panchangDetails`, date, app_language, data_language],
    () =>
      axios.get(url).then((data) => {
        return data.data;
      })
  );
  return (
    <div>
      <div style={{ marginTop: "7.32vw", marginBottom: "7.32vw" }}>
        <h2 className="route-heading">
          {t("Today's")} {t("Panchang")}
        </h2>
        <p className="montserrat-font" style={{ textAlign: "justify" }}>
          {t("Panchang Heading")}
        </p>
      </div>
      <div
        className="bg-white shadow"
        style={{ borderRadius: "1.46vw", padding: "2.92vw" }}
      >
        <div className="d-flex gap-lg-0 gap-2 justify-content-between">
          <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
            <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
              <img src="/img/panchang/sunrise.png" alt="" />
              <div className="d-flex flex-column">
                <small className="panchang-basic-detail-name">
                  {t("Sunrise")}
                </small>
                <small className="panchang-basic-detail-value">
                  {panchangDetails?.Sunrise || "None"}
                </small>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
              <div>
                <img src="/img/panchang/sunset.png" alt="" />
              </div>
              <div className="d-flex flex-column">
                <small className="panchang-basic-detail-name">
                  {t("Sunset")}
                </small>
                <small className="panchang-basic-detail-value">
                  {panchangDetails?.Sunset || "None"}
                </small>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
            <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
              <div>
                <img src="/img/panchang/moonrise.png" alt="" />
              </div>
              <div className="d-flex flex-column">
                <small className="panchang-basic-detail-name">
                  {t("Moonrise")}
                </small>
                <small className="panchang-basic-detail-value">
                  {panchangDetails?.Moonrise || "None"}
                </small>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
              <div>
                <img src="/img/panchang/moonset.png" alt="" />
              </div>
              <div className="d-flex flex-column">
                <small className="panchang-basic-detail-name">
                  {t("Moonset")}
                </small>
                <small className="panchang-basic-detail-value">
                  {panchangDetails?.Moonset || "None"}
                </small>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
            <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
              <small className="panchang-basic-detail-name w-50 mx-auto">
                {t("Purnimanta")}
              </small>
              <small className="w-50 mx-auto panchang-basic-detail-value">
                {panchangDetails?.Chandramasa?.[0]?.split("-")[0] || "None"}
              </small>
            </div>
            <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
              <small className="w-50 mx-auto panchang-basic-detail-name">
                {t("tithi")}
              </small>
              <small className="w-50 mx-auto panchang-basic-detail-value">
                {panchangDetails?.Tithi?.[1] || "None"}
              </small>
            </div>
          </div>
          <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
            <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
              <small className="panchang-basic-detail-name">
                {t("Paksha")}
              </small>
              <small className="panchang-basic-detail-value">
                {panchangDetails?.Paksha || "None"}
              </small>
            </div>
            <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
              <small className="panchang-basic-detail-name">
                {t("Vikram Samvat")}
              </small>
              <small className="panchang-basic-detail-value">
                {panchangDetails?.["Vikram Samvat"]
                  ? panchangDetails?.["Vikram Samvat"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </small>
            </div>
          </div>
        </div>
        <button
          className="d-block mx-auto text-white home-horoscope-details-button mt-3 mt-lg-5"
          onClick={() => navigate("/panchang")}
        >
          View All
        </button>
      </div>
    </div>
  );
};

export default HomePanchang;
