import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Horoscope.scss";
import HoroscopeDetailModal from "./HoroscopeDetailModal";
const HoroscopeDetails = ({ data }) => {
  const { t } = useTranslation();
  const rashi = localStorage.getItem("rashi");
  const horoscopeDetails = data?.[rashi];
  let keys;
  if (horoscopeDetails) {
    keys = Object.keys(horoscopeDetails);
  }

  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = useState("");
  const [modalDataKey, setModalDataKey] = useState("");
  return (
    <div className="py-3 py-lg-5">
      <div className="row g-4 g-lg-5 horoscope-details">
        {keys?.map((key, index) => (
          <div
            key={index}
            className={`col-5 border-0 mx-auto ${
              (index + 1 === 1 && "bg-1") ||
              (index + 1 === 2 && "bg-2") ||
              (index + 1 === 3 && "bg-3") ||
              (index + 1 === 4 && "bg-4") ||
              (index + 1 === 5 && "bg-5") ||
              (index + 1 === 6 && "bg-6")
            }`}
          >
            <div>
              <h6
                className="route-heading"
                style={{
                  fontStyle: "italic",
                  marginBottom: "2.93vw",
                }}
              >
                {t(`${key}`)}
              </h6>
              <p className="text-wrap">
                {horoscopeDetails?.[key].length > 400 ? (
                  <span className="montserrat-font">{`${horoscopeDetails?.[
                    key
                  ].slice(0, 400)}....`}</span>
                ) : (
                  <span className="montserrat-font">
                    {horoscopeDetails?.[key]}
                  </span>
                )}
              </p>
              {horoscopeDetails?.[key].length > 400 && (
                <button
                  variant="primary"
                  onClick={() => {
                    setModalShow(true);
                    setModalData(horoscopeDetails?.[key]);
                    setModalDataKey(key);
                  }}
                  className="text-decoration-underline
                bg-transparent border-0 my-end montserrat-font d-block ms-auto"
                >
                  Read more
                </button>
              )}
            </div>
          </div>
        ))}
        <HoroscopeDetailModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          modaldata={modalData}
          modalDataKey={modalDataKey}
        />
      </div>
    </div>
  );
};

export default HoroscopeDetails;
