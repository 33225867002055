import { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../../pages/UserProfile/userProfile";
import { getFollowUser } from "../../../store/actions/userActions";
import LoginModal from "../Login/LoginModal";
import "./invited.scss";

function Invited({ show, close, invitedUsers, event }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const [userId, setUserId] = useState("");
  const newData = ["usersId", "userMob", "userEmail"];
  function handleFollow(followerId) {
    const data = {
      followingId: userData?.userId,
      followerId: followerId,
    };
    dispatch(getFollowUser(data));
  }

  return (
    <>
      <div>
        <Modal show={show} onHide={close} size="lg">
          <Modal.Header
            closeButton
            style={{ fontSize: "10px", borderBottom: "none" }}
            className="close"
          ></Modal.Header>
          <Modal.Body style={{ padding: "25px" }}>
            <div className="row m-0">
              {newData?.length > 0 && invitedUsers?.length !== 0 ? (
                newData?.map(
                  (value, index) =>
                    invitedUsers?.findIndex((data) => value in data) !== -1 && (
                      <div className="row p-0 g-0" key={index}>
                        <div className="text-center mb-2 mt-2">
                          {value === "usersId"
                            ? "Invited People through registered user"
                            : value === "userEmail"
                            ? "Invited people through Email"
                            : "Invited people through Mobile"}
                        </div>
                        <Card bg="light" className="card-mobile grid-container">
                          {invitedUsers?.length > 0 &&
                            invitedUsers.map((data, index) => {
                              let indexfollow =
                                userData &&
                                userData?.userdetail?.followings?.findIndex(
                                  (value) => value.userId === data?.usersId?._id
                                );
                              if (value in data) {
                                return (
                                  <>
                                    <Card.Body className="padding">
                                      <Card
                                        style={{
                                          width: "10rem",
                                          border: "0px",
                                        }}
                                      >
                                        <Card.Body>
                                          <div>
                                            <img
                                              src={
                                                data?.usersId?.imageURL
                                                  ? data?.usersId?.imageURL
                                                  : "/default/user.png"
                                              }
                                              alt="image"
                                              style={{
                                                width: "80px",
                                                height: "80px",
                                                margin: "22px",
                                                cursor: "pointer",
                                                borderRadius: "10px",
                                              }}
                                              onClick={() => {
                                                setUserId(data?.usersId?._id);
                                                navigate(
                                                  `/userprofile/${data?.usersId?._id}`
                                                );
                                              }}
                                            />
                                          </div>
                                          {data?.userEmail ? (
                                            <div className="text-center detail">
                                              {data?.userEmail}
                                            </div>
                                          ) : null}

                                          {data?.userMob ? (
                                            <div>
                                              {event?.usersId?._id ===
                                              userData?.userdetail?._id ? (
                                                <div>
                                                  <span>
                                                    {data?.userMob
                                                      ? "+91"
                                                      : null}
                                                  </span>
                                                  <span className="ms-1">
                                                    {data?.userMob &&
                                                      data?.userMob}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>
                                                    {data?.userMob
                                                      ? "+91"
                                                      : null}
                                                  </span>
                                                  <span className="ms-1">
                                                    {data?.userMob &&
                                                      data?.userMob.replace(
                                                        /\d(?=\d{4})/g,
                                                        "*"
                                                      )}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          ) : null}

                                          {data?.usersId &&
                                            data?.usersId !== null && (
                                              <div className="detail">
                                                {data?.usersId?.firstName &&
                                                  data?.usersId?.lastName && (
                                                    <div
                                                      className="Name"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setUserId(
                                                          data?.usersId?._id
                                                        );
                                                        navigate(
                                                          `/userprofile/${data?.usersId?._id}`
                                                        );
                                                      }}
                                                    >
                                                      <div className="d-flex justify-content-center ">
                                                        <span>
                                                          {
                                                            data?.usersId
                                                              ?.firstName
                                                          }
                                                        </span>
                                                        <span className="ms-2">
                                                          {
                                                            data?.usersId
                                                              ?.lastName
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                {data?.usersId?.city &&
                                                  data?.usersId?.country && (
                                                    <div className="mt-1 d-flex justify-content-center">
                                                      <span>
                                                        {data?.usersId?.city}
                                                      </span>
                                                      <span className="ms-1">
                                                        {data?.usersId?.country}
                                                      </span>
                                                    </div>
                                                  )}
                                              </div>
                                            )}
                                          {data?.usersId &&
                                          data?.usersId?._id !==
                                            userData?.userId &&
                                          data?.usersId !== undefined &&
                                          token ? (
                                            <div className="mt-2 d-flex align-items-center justify-content-center">
                                              <button
                                                className="follow"
                                                onClick={() =>
                                                  !token
                                                    ? setShowLogin(true)
                                                    : handleFollow(
                                                        data?.usersId._id
                                                      )
                                                }
                                              >
                                                {userData &&
                                                userData?.userdetail &&
                                                userData?.userdetail
                                                  ?.followings[indexfollow]
                                                  ?.userId ===
                                                  data?.usersId?._id
                                                  ? "Unfollow"
                                                  : "Follow"}
                                              </button>
                                            </div>
                                          ) : null}
                                        </Card.Body>
                                      </Card>
                                    </Card.Body>
                                  </>
                                );
                              }
                            })}
                        </Card>
                      </div>
                    )
                )
              ) : (
                <div className="row nodata">
                  <div className="col-md-10 col-sm-10 col-lg-10 nodatawrapper">
                    <h3 className="noDataContent m-0">
                      There is no People invited.
                    </h3>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
      />
      {userId && <UserProfile />}
    </>
  );
}
export default Invited;
