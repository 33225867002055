import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import KundliHeading from "./KundliHeading";
import KundliButtons from "./KundliButtons";

export const KundliContext = React.createContext({});
// const matchedInfo = JSON.parse(localStorage.getItem("matchedInfo"));

const validate = (values) => {
  const submitButton = document.getElementById("submit-button");
  const errors = {};
  // if ((values.name.length > 3 && values.place.length >= 5) || matchedInfo) {
  if (values.name.length >= 3 && values.place.length >= 5) {
    submitButton.removeAttribute("disabled");
  } else if (!values.name) {
    errors.name = "Required";
    submitButton.setAttribute("disabled", true);
  } else if (!values.place) {
    errors.place = "Required";
    submitButton.setAttribute("disabled", true);
  } else if (values.name.length < 3) {
    errors.name = "Must be 3 characters or more";
    submitButton.setAttribute("disabled", true);
  } else if (values.place.length < 5) {
    errors.place = "Must be 5 characters or More";
    submitButton.setAttribute("disabled", true);
  } else {
    submitButton.setAttribute("disabled", true);
  }
  return errors;
};

const Kundli = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const submitButton = document.getElementById("submit-button");
  const [locationData, setLocationData] = useState({});
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const [userInfo, setUserInfo] = useState({});
  // const [name, setName] = useState("");
  // const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const matchedInfo = storedUserInfo?.find((info) => info.name === name);
  // matchedInfo &&
  //   localStorage.setItem("matchedInfo", JSON.stringify(matchedInfo));

  const formik = useFormik({
    initialValues: {
      // name: matchedInfo ? matchedInfo.name : "",
      // gender: matchedInfo ? matchedInfo.gender : "Male",
      // day: matchedInfo ? matchedInfo.day : new Date().getDate(),
      // month: matchedInfo ? matchedInfo.month : new Date().getMonth() + 1,
      // year: matchedInfo ? matchedInfo.year : new Date().getFullYear(),
      // hour: matchedInfo
      //   ? matchedInfo.hour
      //   : new Date().getHours() > 12
      //   ? new Date().getHours() - 12
      //   : new Date().getHours(),
      // min: matchedInfo ? matchedInfo.min : new Date().getMinutes(),
      // clockTime: matchedInfo
      //   ? matchedInfo.clockTime
      //   : new Date().getHours() >= 12
      //   ? "PM"
      //   : "AM",
      // place: matchedInfo ? matchedInfo.place : "",
      name: "",
      gender: "Male",
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      hour:
        new Date().getHours() > 12
          ? new Date().getHours() - 12
          : new Date().getHours(),
      min: new Date().getMinutes(),
      clockTime: new Date().getHours() >= 12 ? "PM" : "AM",
      place: "",
    },
    validate,
    onSubmit: (values) => {
      values.lat = locationData?.geometry?.location?.lat();
      values.lon = locationData?.geometry?.location?.lng();
      let hour = parseInt(values.hour);
      if (values.clockTime === "PM") {
        if (hour < 12) {
          hour = hour + 12;
        }
      } else if (values.clockTime === "AM") {
        if (hour > 12) {
          hour = hour - 12;
        } else if (hour === 12) {
          hour = 0;
        }
      }

      values.day = parseInt(values.day);
      values.month = parseInt(values.month);
      values.year = parseInt(values.year);
      values.hour = hour;
      values.min = parseInt(values.min);
      values.tzone = 5.5;
      localStorage.setItem("userInfo", JSON.stringify(values));
      // setUserInfo(values);
      // const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
      // if (storedUserInfo) {
      //   storedUserInfo.map((info) => {
      //     if (info?.name === values?.name) {
      //       return info === values;
      //     } else {
      //       const newUserInfo = [...storedUserInfo, values];
      //       localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
      //     }
      //     return 0;
      //   });
      // } else {
      //   localStorage.setItem("userInfo", JSON.stringify([values]));
      // }
      formik.resetForm();
      submitButton.setAttribute("disabled", true);
    },
  });

  const kundliContextValues = {
    userInfo,
    formik,
    // setName,
    // matchedInfo,
    setLocationData,
  };

  return (
    <KundliContext.Provider value={kundliContextValues}>
      <div
        style={{
          padding: "5.3%",
          marginTop: "60px",
          backgroundColor: "#FFE8D8",
        }}
      >
        <KundliHeading />

        <KundliButtons />
      </div>
    </KundliContext.Provider>
  );
};

export default Kundli;
