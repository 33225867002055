import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../../Components/Card/Card";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
const NearByEvents = ({ nearByEvents }) => {
  const { t } = useTranslation();
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowLeft
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "55px",
          width: "55px",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowRight
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "55px",
          width: "55px",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: nearByEvents?.length > 2 ? 3 : nearByEvents?.length,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  return (
    <div>
      <h1 className="events-section-header">{t("Nearby Events")}</h1>
      <div className="events-div" style={{ background: "#EBD4FF" }}>
        {nearByEvents?.length > 0 ? (
          <Slider {...settings}>
            {nearByEvents?.map((event, index) => (
              <Card key={index} event={event} index={index} />
            ))}
          </Slider>
        ) : (
          <span className="events-section-header text-center">
            You Don't Have Any Nearby Event Right Now
          </span>
        )}
      </div>
    </div>
  );
};

export default NearByEvents;
