import { Dropdown, DropdownButton, Offcanvas } from "react-bootstrap";
import { RiSearchLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "./sidemenudrawer.scss";
import "../Header/header.scss";
import { BiCurrentLocation } from "react-icons/bi";
import { usePlacesWidget } from "react-google-autocomplete";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { nearByEventApi } from "../../store/actions/eventActions";

export default function Sidemenudrawer({
  show,
  token,
  HandleLanguage,
  setchanSearch,
  chanSearch,
  selectedLang,
  setSearch,
  handleSearch,
  handleShowCreate,
  handleLogout,
  handleShowLogin,
  options,
  search,
  all,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const { t, i18n } = useTranslation();
  const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const limit = "25";

  // const { ref } = usePlacesWidget({
  //   apiKey: GOOGLE_MAPS_KEY,
  //   onPlaceSelected: async (place) => {
  //     console.log(place, "hello");
  //     sessionStorage.setItem("temp_location_name", place?.formatted_address);
  //     await sessionStorage.setItem(
  //       "tempLocation",
  //       JSON.stringify({
  //         lat: place?.geometry?.location?.lat(),
  //         lng: place?.geometry?.location?.lng(),
  //       })
  //     );
  //     const data = {
  //       eventStatus: "APPROVED",
  //       distance: 300000,
  //       language: user?.userdetail?.lang
  //         ? user?.userdetail?.language
  //         : localStorage.getItem("REACT_APP_LANGUAGE"),
  //       nearBy: true,
  //       lat: place?.geometry?.location?.lat(),
  //       long: place?.geometry?.location?.lng(),
  //     };
  //     dispatch(nearByEventApi(data));
  //   },
  // });

  const cls = show ? "showdrawer" : "hidedrawer";

  const tost = () => {
    toast.warning("Please Complete Your Profile First", { theme: "colored" });
  };

  const handleTruncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };

  return (
    <>
      <div className={`sidenavdraw ${cls}`}>
        <ul className="side-menu text-capitalize" id="menu">
          {token && (
            <li
              className="profile-image d-flex d-xl-none align-items-center"
              style={{ paddingLeft: "30px" }}
            >
              <div
                className="loggedIn"
                ref={(el) => {
                  if (el && user?.userdetail?.imageURL) {
                    el.style.setProperty("border", "none", "important");
                  }
                }}
              >
                {user?.userdetail?.imageURL ? (
                  <img
                    alt=""
                    src={user?.userdetail?.imageURL}
                    className="rounded-circle"
                  />
                ) : (
                  <img alt="" src="/img/1553336600.png" />
                )}
              </div>
              <div className="ms-4">
                <div className="name detail-info">
                  <div className="mt-1 ">
                    Name : <span>{user && user?.userdetail?.firstName}</span>
                  </div>
                  <div className="mt-1">
                    Language : <span>{user && user?.userdetail?.language}</span>
                  </div>
                  <div className="mt-1">
                    Address :
                    <span>
                      {user && user?.userdetail?.city},{" "}
                      {user && user?.userdetail?.country}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          )}
          <li className="d-xl-none d-md-none p-2" style={{ borderTop: "0px" }}>
            {selectedLang && (
              <Select
                options={options}
                classNamePrefix={"selectbox"}
                onChange={HandleLanguage}
                defaultValue={
                  selectedLang === "MS"
                    ? all[0]
                    : options[
                        options.findIndex(
                          (event) => event.value === selectedLang
                        )
                      ]
                }
              />
            )}
          </li>
          <li className="d-xl-none p-2" style={{ borderTop: "0px" }}>
            <div className="searchMain w-100">
              <form>
                <input
                  type="text"
                  name="search"
                  className="form-control search-box ps-5"
                  autoComplete="off"
                  placeholder="Search"
                  id="tags"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    handleSearch(e.target.value);
                  }}
                />
                <span className="btn search-button searchicon">
                  <RiSearchLine />
                </span>
                <span className="vertical ver" />
                <div className="action actionside">
                  <DropdownButton id="dropdown-basic-button" title={chanSearch}>
                    <Dropdown.Item
                      className="p-0"
                      onClick={() => setchanSearch("Users")}
                    >
                      {t("Users")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="p-0"
                      onClick={() => setchanSearch("Events")}
                    >
                      {t("Events")}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </form>
            </div>
          </li>
          {/* <li
            className="d-xl-none d-md-none d-flex p-2 m-0"
            style={{ borderTop: "0px" }}
          >
            <span className="location-side">
              <BiCurrentLocation />
            </span>
            <input
              className="form-control search-box ps-5 loc"
              ref={ref}
              type="text"
              defaultValue={
                sessionStorage.getItem("temp_location_name")
                  ? sessionStorage.getItem("temp_location_name")
                  : null
              }
              placeholder={handleTruncateText(t("Enter_Location"), limit)}
            />
          </li> */}
          <li style={{ borderTop: "0px" }} className="menu-li">
            <span
              className="browse-event"
              onClick={() => navigate("/horoscope")}
            >
              {t("Horoscope")}
            </span>
          </li>
          <li className="menu-li">
            <span
              className="browse-event"
              onClick={() => navigate("/panchang")}
            >
              {t("Panchang")}
            </span>
          </li>
          <li className="menu-li">
            <span className="browse-event" onClick={() => navigate("/kundli")}>
              {t("Kundli")}
            </span>
          </li>
          <li className="menu-li">
            <span
              onClick={() =>
                user?.newUser === false || !token
                  ? navigate("/event")
                  : token
                  ? tost()
                  : ""
              }
              style={{ borderTop: "0px", cursor: "pointer" }}
              className="browse-event"
            >
              {t("Browse Events")}
            </span>
          </li>
          {token ? (
            <li className="menu-li">
              <span
                style={{ borderTop: "0px", cursor: "pointer" }}
                className="browse-event"
                onClick={() => handleShowCreate()}
              >
                {t("Create Events")}
              </span>
            </li>
          ) : (
            <li className="menu-li">
              <span
                style={{ borderTop: "0px", cursor: "pointer" }}
                className="browse-event"
                onClick={() => handleShowLogin()}
              >
                {t("Create Events")}
              </span>
            </li>
          )}
          <li className="menu-li">
            <span
              className="browse-event"
              onClick={() => navigate("/articles")}
            >
              {t("Articles")}
            </span>
          </li>
          <li className="menu-li">
            <span
              className="browse-event"
              onClick={() => navigate("/matrimony")}
            >
              {t("Matrimony")}
            </span>
          </li>
          {token && (
            <>
              <li>
                <a
                  href="/e/manage/past"
                  style={{ borderTop: "0px" }}
                  className="browse-event"
                >
                  {t("Manage Events")}
                </a>
              </li>
              <li>
                <a href="/profile" className="browse-event">
                  {t("Manage Profile")}
                </a>
              </li>
            </>
          )}
          {token && (
            <>
              <li>
                <a
                  href={`/userprofile/${user?.userId}`}
                  className="browse-event"
                >
                  {t("View Profile")}
                </a>
              </li>
            </>
          )}
          {token && (
            <li>
              <a href=" " className="signin-btn" onClick={() => handleLogout()}>
                {t("Logout")}
              </a>
            </li>
          )}
          {!token && (
            <li>
              <a
                href=" "
                className="signin-btn"
                onClick={() => handleShowLogin()}
              >
                {t("Sign In")}
              </a>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
