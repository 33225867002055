import React from "react";
import { useTranslation } from "react-i18next";

const KundliHeading = () => {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "7.32vw" }}>
      <h2 className="route-heading">{t("Kundli")}</h2>
      <p className="montserrat-font" style={{ textAlign: "justify" }}>
        {t("Kundli Heading")}
      </p>
    </div>
  );
};

export default KundliHeading;
