import "./slider.scss";

function Slider({ t }) {
  const banner = localStorage.getItem("REACT_APP_BANNER");
  return (
    <div style={{ paddingTop: "60px" }}>
      <section className="homepage-slider">
        <div className="slider_img">
          <img
            alt=""
            src={banner}
            className="img-fluid"
            style={{ height: "300px", width: "100%" }}
          ></img>
        </div>
        <div className="slider_content">
          {/* <h2 className="slider_title">{t("mainImageText")}</h2> */}
        </div>
      </section>
    </div>
  );
}

export default Slider;
