import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const AnandadiAndTamilYoga = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center details-header">
        {`${t("Anandadi")} ${t("and")} ${t("Tamil Yoga")}`}
      </div>
      <div className="details-body panchang-gradient-1">
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Anandadi Yoga */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">
                {`${t("Anandadi")} ${t("Yoga")}`}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Anandadi Yoga"]
                  ? panchangDetails?.["Anandadi Yoga"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Tamil Yoga */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Tamil Yoga")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Tamil Yoga"]
                  ? panchangDetails?.["Tamil Yoga"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Jeevanama */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Jeevanama")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Jeevanama"]
                  ? panchangDetails?.["Jeevanama"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Netrama */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Netrama")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Netrama"]
                  ? panchangDetails?.["Netrama"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnandadiAndTamilYoga;
