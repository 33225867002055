import axios from "axios";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
const useHoroscopeFetch = (date, week, month, year) => {
  const rashi = localStorage.getItem("rashi");
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const [data, setData] = useState([]);

  const baseUrl = `${process.env.REACT_APP_ENDPOINT}rashi?rashi=${rashi}&language=${language}`;
  let url;
  if (date) {
    url = `${baseUrl}&day=${date}`;
  } else if (month) {
    url = `${baseUrl}&month=${month}`;
  } else if (week) {
    url = `${baseUrl}&week=${week}`;
  } else {
    url = `${baseUrl}&year=${year}`;
  }
  const { refetch, isLoading } = useQuery([`data`, rashi, language], () =>
    axios.get(url).then((data) => {
      setData(data?.data);
      return data.data;
    })
  );
  return { data, refetch, isLoading };
};

export default useHoroscopeFetch;
