import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const LunarMonthAndSamvat = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center details-header">
        {t("Lunar Month and Samvat")}
      </div>

      <div className="details-body panchang-gradient-2">
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Vikram Samvat */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">
                {t("Vikram Samvat")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Vikram Samvat"]
                  ? panchangDetails?.["Vikram Samvat"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Shaka Samvat */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Shaka Samvat")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Shaka Samvat"]
                  ? panchangDetails?.["Shaka Samvat"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Gujarati Samvat */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Gujarati Samvat")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Gujarati Samvat"]
                  ? panchangDetails?.["Gujarati Samvat"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Chandramasa */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">{t("Chandramasa")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Chandramasa"]
                  ? panchangDetails?.["Chandramasa"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LunarMonthAndSamvat;
