import React, { createContext, useEffect, useState } from "react";
import Today from "./Today";
import Tomorrow from "./Tomorrow";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Yearly from "./Yearly";
import HoroscopeHeading from "./HoroscopeHeading";
// import { Adsense } from "@ctrl/react-adsense";
// import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";

export const HoroscopeContext = createContext("");
const Horoscope = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [horoscopePeriod, setHoroscopePeriod] = useState("Today's Horoscope");
  const handleHoroscopePeriod = (horoscopePeriod) => {
    setHoroscopePeriod(horoscopePeriod);
  };
  const horoscopeContext = { horoscopePeriod, handleHoroscopePeriod };

  return (
    <HoroscopeContext.Provider value={horoscopeContext}>
      <div
        style={{
          padding: "5.3%",
          marginTop: "60px",
          backgroundColor: "#FFE8D8",
        }}
      >
        <div>
          {/* <Adsense
        client="ca-pub-3052504703995882"
        slot="5066012899"
        style={{ display: "block" }}
        layout="in-article"
        format="fluid"
      /> */}
          {/* <GoogleAdSense /> */}

          <HoroscopeHeading />
          {horoscopePeriod === "Today's Horoscope" && <Today />}
          {horoscopePeriod === "Tomorrow's Horoscope" && <Tomorrow />}
          {horoscopePeriod === "Weekly Horoscope" && <Weekly />}
          {horoscopePeriod === "Monthly Horoscope" && <Monthly />}
          {horoscopePeriod === "Yearly Horoscope" && <Yearly />}
        </div>
      </div>
    </HoroscopeContext.Provider>
  );
};

export default Horoscope;
