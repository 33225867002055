import React, { useState } from "react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import "./signUp.scss";

function Signup() {
  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Must be 6 characters or more";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      conpassword: "",
    },
    validate,
    onSubmit: (values) => {},
  });

  return (
    <>
      <div style={{ paddingTop: "3.3%" }}>
        <div className="container page-main-contain">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-12 text-center">
              <h1>Welcome</h1>
              <p>Create an Account</p>
            </div>
          </div>
          <div className="row sign-in-form">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <Form onSubmit={formik.handleSubmit} className="contact-form">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12 form-group">
                    <Form.Group size="lg" controlId="email">
                      <Form.Control
                        className="email"
                        name="email"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="Enter Email"
                      />
                      {formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12 form-group">
                    <Form.Group size="lg" controlId="firstname">
                      <Form.Control
                        className="email"
                        name="firstname"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstname}
                        placeholder="Enter Firstname"
                      />
                      {formik.errors.firstname ? (
                        <div className="error">{formik.errors.firstname}</div>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12 form-group">
                    <Form.Group size="lg" controlId="lastname">
                      <Form.Control
                        className="email"
                        name="lastname"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastname}
                        placeholder="Enter Lastname"
                      />
                      {formik.errors.lastname ? (
                        <div className="error">{formik.errors.lastname}</div>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12 form-group">
                    <Form.Group size="lg" controlId="password">
                      <Form.Control
                        className="email"
                        type="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        placeholder="Enter Password"
                      />
                      {formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12 form-group">
                    <Form.Group size="lg" controlId="conpassword">
                      <Form.Control
                        className="email"
                        type="password"
                        name="conpassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.conpassword}
                        placeholder="Confirm Password"
                      />
                      {formik.errors.conpassword ? (
                        <div className="error">{formik.errors.conpassword}</div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12 col-ms-12 col-lg-12">
                    <p className="sign-up-links">
                      By signing up, I agree to Eventz 's{" "}
                      <a href="pages/terms-and-condtion.html">
                        {" "}
                        terms of service
                      </a>
                      ,{" "}
                      <a href="pages/privacy-and-policy.html">privacy policy</a>
                      , and community guidelines.
                    </p>
                  </div>
                  <div className="col-md-5 col-ms-12 col-lg-5">
                    <button className="submit" type="submit">
                      SUBMIT
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;

//
//   </div>
// </form>
// </div>
// </div>
// </div> */}
