import { Dropdown, Modal } from 'react-bootstrap'
import './newEvent.scss'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { IoCloseSharp } from 'react-icons/io5'
import {
  categoriesApi,
  createEventApi,
  getShareEmails,
  showCategories,
  UpdateEventApi
} from '../../../store/actions/eventActions'
import {
  showFile,
  showImage,
  uploadFileApi,
  uploadImageApi
} from '../../../store/actions/fileActions'
import { fileAction } from '../../../store/slice/fileSlice'
import { close_popup } from '../../../store/slice/eventSlice'
import CreatableSelect from 'react-select/creatable'
import { useTranslation } from 'react-i18next'
import GoogleContacts from 'react-google-contacts'
import validationFileExtension from '../../../validationFile'
import { getInviteFollowers } from '../../../store/actions/userActions'
import Select from 'react-select'

function NewEvent ({ show, close, event, setSelectedEvent }) {
  const regIMG = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+(?:png|jpg|jpeg|gif|svg|bmp|webp|tiff?|apng|avif|svg)+$/
  const dispatch = useDispatch()
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const { t } = useTranslation()
  const userData = useSelector(state => state?.user?.userdetail)
  const userFollowers = useSelector(state => state?.SearchUser?.userfollowers)
  const [clearImage, setClearImage] = useState(false)
  const [clear, setClear] = useState(false)
  const [file, setFile] = useState('')
  const [list, setList] = useState(event ? event?.attachments : [])
  const [mobiles, setMobiles] = useState([])
  const [location, setLocation] = useState('')
  const categories = useSelector(showCategories)
  const imageUploaded = useSelector(showImage)
  const fileUploaded = useSelector(showFile)
  const isClose = useSelector(state => state?.event?.isClose)
  // const EmailList = useSelector((state) => state?.event?.shareEmails);
  const [inviteEmail, setInviteEmail] = useState([])
  const [Users, setUsers] = useState([])
  const [attachment, setAttachment] = useState('Image')
  const [options, setOptions] = useState([])

  const responseCallback = response => {
    if (response?.error !== 'popup_closed_by_user') {
      let options = response.map(item => ({
        value: item?.email,
        label: item?.email
      }))
      setUsers(options)
    }
  }

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: place => {
      setLocation(place?.geometry)
      formik.setFieldValue(
        'location1',
        place?.formatted_address ? place?.formatted_address : ''
      )
      formik.setFieldValue(
        'city',
        place?.address_components[0]?.long_name
          ? place?.address_components[0]?.long_name
          : ''
      )
      formik.setFieldValue(
        'state',
        place?.address_components[2]?.long_name
          ? place?.address_components[2]?.long_name
          : ''
      )
      formik.setFieldValue(
        'latitude',
        place?.geometry?.location?.lat()
          ? place?.geometry?.location?.lat()
          : null
      )
      formik.setFieldValue(
        'longitude',
        place?.geometry?.location?.lng()
          ? place?.geometry?.location?.lng()
          : null
      )
    }
  })

  const HandleInviteUser = event => {
    let Emailss = event?.map(item => item?.value)
    setInviteEmail(Emailss)
  }
  const validate = values => {
    const errors = {}
    if (!values.title) {
      errors.title = 'Required'
    } else if (values.title.length < 5) {
      errors.title = 'Must be 5 characters or more'
    }
    if (!values.startTime) {
      errors.startTime = 'Required'
    }
    if (!values.endTime) {
      errors.endTime = 'Required'
    }
    if (!values.description) {
      errors.description = 'Required'
    }
    if (!values.category) {
      errors.category = 'Required'
    }
    if (!values.address) {
      errors.address = 'Required'
    }
    if (!values.location1) {
      errors.location1 = 'Required'
    }
    if (!values.event_image) {
      errors.event_image = 'Required'
    }
    if (!values.startdate) {
      errors.startdate = 'Required'
    }
    if (!values.enddate) {
      errors.enddate = 'Required'
    }
    if (!values.startTime) {
      errors.startTime = 'Required'
    }
    if (!values.endTime) {
      errors.endTime = 'Required'
    }
    if (!values.latitude) {
      errors.latitude = 'Required'
    }
    if (!values.longitude) {
      errors.longitude = 'Required'
    }
    return errors
  }
  const formik = useFormik({
    initialValues: {
      title: event ? event?.title : '',
      startTime: event ? handleStartTime() : '',
      endTime: event ? handleEndTime() : '',
      description: event ? event?.description : '',
      address: event ? event?.address2 : '',
      location1: event ? event?.address : '',
      city: event ? event?.city : '',
      state: event ? event?.state : '',
      pincode: event ? event?.pincode : '',
      phone_number: event ? event?.phone : '',
      category: event ? event?.categoryId?._id : '',
      isPrivate: event ? event?.isPrivate : false,
      startdate: event ? moment(event?.startDate).toDate() : '',
      enddate: event ? moment(event?.endDate).toDate() : '',
      event_file: '',
      event_image:
        event && !imageUploaded?.image?.length ? event?.imageURL : '',
      emails: inviteEmail,
      longitude: event ? event?.location?.coordinates[0] : '',
      latitude: event ? event?.location?.coordinates[1] : '',
      mobiles: '',
      users: ''
    },
    validate,
    onSubmit: values => {
      const data = {
        categoryId: values.category,
        title: values.title,
        description: values.description,
        address: values.location1,
        address2: values.address,
        location: {
          type: 'Point',
          coordinates: [
            event ? event?.location?.coordinates[0] : values?.longitude,
            event ? event?.location?.coordinates[1] : values?.latitude
          ]
        },
        city: values.city,
        state: values.state,
        imageURL:
          event && !imageUploaded?.image?.files?.length > 0
            ? event?.imageURL
            : imageUploaded?.image && imageUploaded?.image?.files[0]?.url,
        startDate: moment(values.startdate).format('YYYY-MM-DD'),
        startTime: moment(values?.startTime).format('HH:mm'),
        endDate: moment(values.enddate).format('YYYY-MM-DD'),
        endTime: moment(values?.endTime).format('HH:mm'),
        language: localStorage.getItem('REACT_APP_LANGUAGE'),
        isPrivate:
          values?.isPrivate == true || values?.isPrivate == 'true'
            ? true
            : false,
        usersId: userData?._id,
        pincode: values.pincode,
        phone: values?.phone_number,
        eventId: event?._id,
        attachments: list,
        emails: inviteEmail,
        mobiles: mobiles,
        users:
          values?.users?.length > 0 && values?.users?.map(users => users.value),
        isVerified: userData.isVerified
      }
      if (!event) {
        dispatch(createEventApi(data))
      } else {
        dispatch(UpdateEventApi(data))
      }
    }
  })
  const handleImageEvent = image => {
    dispatch(uploadImageApi(image))
  }
  const handleFile = file => {
    setFile(file)
    dispatch(uploadFileApi(file))
  }
  useEffect(() => {
    const data = {
      userId: userData?._id
    }
    dispatch(categoriesApi())
    dispatch(getShareEmails())
    dispatch(getInviteFollowers(data))
  }, [dispatch])

  useEffect(() => {
    if (isClose === true) {
      close()
      dispatch(close_popup())
    }
  }, [isClose, dispatch, close])
  function handleDeleteFile (id, url) {
    if (id !== undefined) {
      setList(list?.filter(value => value._id !== id))
    } else {
      setList(list?.filter(value => value.fileurl !== url))
    }
  }

  useEffect(() => {
    if (fileUploaded?.length !== 0) {
      setList(list => [...list, fileUploaded])
    }
  }, [fileUploaded])

  function handleStartTime () {
    const startTime = event?.startTime?.split(':')
    var now = new Date()
    now.setHours(startTime[0])
    now.setMinutes(startTime[1])
    return moment(new Date(now)).toDate()
  }

  function handleEndTime () {
    const endTime = event?.endTime?.split(':')
    var now = new Date()
    now.setHours(endTime[0])
    now.setMinutes(endTime[1])
    return moment(new Date(now)).toDate()
  }
  useEffect(() => {
    const numbers = formik.values.mobiles
    setMobiles(numbers.split(','))
  }, [formik.values.mobiles])

  const attachmentTypes = [
    {
      fileType: 'Image',
      value: 'image/*'
    },
    {
      fileType: 'Audio',
      value: 'audio/*'
    },
    {
      fileType: 'File',
      value: 'all'
    }
  ]

  useEffect(() => {
    if (userFollowers?.length > 0) {
      let users = userFollowers.map(item => ({
        value: item?._id,
        label: item?.firstName + ' ' + item?.lastName
      }))
      setOptions(users)
    }
  }, [userFollowers?.length])

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          formik.resetForm('')
          setClear('')
          setClearImage('')
          setFile('')
          close()
          dispatch(fileAction.clearFileData())
          dispatch(fileAction.clearImageData())
          event && setSelectedEvent('')
        }}
        size='lg'
      >
        <Modal.Header style={{ fontSize: '10px', padding: '24px' }}>
          <h2
            className='profile-title profile-title-text page-header'
            style={{ marginBottom: '0', lineHeight: '0.7', border: 'none' }}
          >
            {!event ? t('Add_New_Event') : 'Edit Event'}
          </h2>
          <button
            type='button'
            className='btn-close'
            aria-label='Close'
            style={{ opacity: '.2', border: '0', background: 'transperant' }}
            onClick={() => {
              formik.resetForm('')
              setClear('')
              setClearImage('')
              setFile('')
              close()
              dispatch(fileAction.clearFileData())
              dispatch(fileAction.clearImageData())
              event && setSelectedEvent('')
            }}
          ></button>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: '#f1f1f1',
            padding: '24px'
          }}
        >
          <form
            onSubmit={formik.handleSubmit}
            onKeyDown={e => (e.key === 'Enter' ? e.preventDefault() : '')}
          >
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 form-group'>
                <label className='label-text'>
                  {t('Event_Categories')} <span className='text-danger'>*</span>
                </label>
                <select
                  className='form-control form-textbox k-state'
                  name='category'
                  id='category'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.category}
                >
                  <option value=''>{t('Select')}</option>
                  {categories?.length > 0 &&
                    categories?.map((value, index) => {
                      return (
                        <option value={value?._id} key={index}>
                          {value?.categoryName}
                        </option>
                      )
                    })}
                </select>
                {formik.touched.category && formik.errors.category ? (
                  <div className='error'>{formik.errors.category}</div>
                ) : null}
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>
                  {t('Event_title')} <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  name='title'
                  id='title'
                  className='form-control form-textbox'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className='error'>{formik.errors.title}</div>
                ) : null}
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group text-capitalize'>
                <label className='label-text'>
                  {t('description')} <span className='text-danger'>*</span>
                </label>
                <textarea
                  name='description'
                  id='description'
                  className='summernote form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                ></textarea>
                {formik.touched.description && formik.errors.description ? (
                  <div className='error'>{formik.errors.description}</div>
                ) : null}
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12 form-group'>
                <label className='label-text'>
                  {t('Address')} <span className='text-danger'>*</span>
                </label>
                <textarea
                  name='address'
                  id='address'
                  className='form-control form-textbox'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  rows='3'
                  cols='5'
                ></textarea>
                {formik.touched.address && formik.errors.address ? (
                  <div className='error'>{formik.errors.address}</div>
                ) : null}
              </div>
              <div
                className='col-lg-12 col-sm-12 col-md-12 form-group'
                id='location-lat-lang'
                style={{ display: 'block' }}
              >
                <label className='label-text'>
                  {t('Location')} <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control form-textbox text-capitalize'
                  ref={ref}
                  type='text'
                  name='location1'
                  placeholder={t('Placeholder_Address')}
                  value={formik.values.location1}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.location1 && formik.errors.location1 ? (
                  <div className='error'>{formik.errors.location1}</div>
                ) : null}
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>{t('City')}</label>
                <input
                  type='text'
                  name='city'
                  id='city'
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  className='form-control form-textbox'
                  onChange={formik.handleChange}
                />
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>{t('State')}</label>
                <input
                  type='text'
                  name='state'
                  id='state'
                  className='form-control form-textbox'
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                />
              </div>
              {event && !clear ? (
                <div className='d-flex justify-content-center'>
                  <span>
                    <IoCloseSharp
                      className='icon-close'
                      onClick={() => {
                        setClear(true)
                        dispatch(fileAction.clearImageData())
                      }}
                    />
                  </span>
                  <span className='ms-2'>
                    <img
                      src={event?.imageURL}
                      alt=''
                      id='ingOup'
                      className='required img-thumbnail'
                      style={{ height: '150px', border: 'none' }}
                    />
                  </span>
                </div>
              ) : imageUploaded?.image?.files?.length > 0 ? (
                <div className='d-flex justify-content-center'>
                  <span>
                    <IoCloseSharp
                      className='icon-close'
                      onClick={() => {
                        setClearImage(true)
                        dispatch(fileAction.clearImageData())
                      }}
                    />
                  </span>
                  <span>
                    <img
                      src={
                        imageUploaded?.image?.files?.length > 0 &&
                        imageUploaded?.image?.files[0]?.url
                      }
                      alt=''
                      id='ingOup'
                      className='required img-thumbnail'
                      style={{ height: '150px', border: 'none' }}
                    />
                  </span>
                </div>
              ) : (
                <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                  <label className='label-text'>
                    {t('Event_Image')} <span className='text-danger'>*</span>
                  </label>
                  <div className=' form-control form-textbox text-center'>
                    <input
                      type='file'
                      name='event_image'
                      id='image'
                      style={{ display: 'none' }}
                      accept='image/*'
                      onChange={event => {
                        formik.setFieldValue(
                          'event_image',
                          event.currentTarget.files[0]
                        )
                        handleImageEvent(event.currentTarget.files[0])
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor='image'>
                      <span>
                        <FaCloudUploadAlt
                          style={{ height: '30px', width: '30px' }}
                        />
                      </span>
                      <span className='ms-2'>{t('Upload_Image')}</span>
                    </label>
                  </div>
                  {formik.touched.event_image && formik.errors.event_image ? (
                    <div className='error'>{formik.errors.event_image}</div>
                  ) : null}
                </div>
              )}
              <div className='col-lg-12 col-sm-12 col-md-12 form-group button'>
                <label className='label-text'>{t('Attachment')}</label>
                <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                  <div className='d-flex form-control form-textbox'>
                    <Dropdown
                      className='col-lg-3'
                      style={{ display: 'inline-grid', justifyContent: 'end' }}
                    >
                      <Dropdown.Toggle drop='down'>
                        <span className='me-2'>{attachment}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          border: 'unset',
                          borderRadius: 'unset',
                          width: '100%'
                        }}
                      >
                        <Dropdown.Item
                          href='#'
                          onClick={() => setAttachment('Image')}
                        >
                          Image
                        </Dropdown.Item>
                        <Dropdown.Item
                          href='#'
                          onClick={() => setAttachment('Audio')}
                        >
                          Audio
                        </Dropdown.Item>
                        <Dropdown.Item
                          href='#'
                          onClick={() => setAttachment('File')}
                        >
                          File
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className='divider ms-2' />
                    <div className='col-lg-8 d-flex justify-content-center align-items-center ms-2'>
                      <input
                        type='file'
                        name='event_file'
                        id='file'
                        accept={
                          attachment === 'Image'
                            ? 'image/*'
                            : attachment === 'Audio'
                            ? 'audio/*'
                            : 'all'
                        }
                        style={{ display: 'none' }}
                        onChange={event => {
                          formik.setFieldValue(
                            'event_file',
                            event.currentTarget.files[0]
                          )
                          handleFile(event.currentTarget.files[0])
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor='file'>
                        <span>
                          <FaCloudUploadAlt
                            style={{ height: '30px', width: '30px' }}
                          />
                        </span>
                        <span className='ms-2'>
                          {attachment === 'Image'
                            ? 'Upload image from here'
                            : attachment === 'Audio'
                            ? 'Upload audio file from here'
                            : 'Upload File'}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center ms-3'>
                  {list?.length > 0 &&
                    list?.map((value, index) => {
                      return (
                        <div style={{ paddingLeft: '20px' }} key={index}>
                          <span>
                            <IoCloseSharp
                              className='icon-close'
                              onClick={() =>
                                handleDeleteFile(value?._id, value?.fileurl)
                              }
                            />
                          </span>
                          <span className='ms-2'>
                            {regIMG?.test(value?.fileurl) ? (
                              <a
                                href={value?.fileurl}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <img
                                  src={
                                    value?.fileurl &&
                                    value?.fileurl !== undefined
                                      ? value?.fileurl
                                      : '/default/user.png'
                                  }
                                  className='text-center'
                                  alt='user_image'
                                  style={{ width: '100px', height: '100px' }}
                                />
                              </a>
                            ) : (
                              <a
                                href={value?.fileurl}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {validationFileExtension(value?.fileurl)}
                              </a>
                            )}
                          </span>
                        </div>
                      )
                    })}
                </div>
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>
                  {t('Longitude')} <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  name='longitude'
                  id='longitude'
                  className='form-control form-textbox'
                  value={formik.values.longitude}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.longitude && formik.touched.longitude ? (
                  <div className='error'>{formik.errors.longitude}</div>
                ) : null}
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>
                  {t('Latitude')} <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  name='latitude'
                  id='latitude'
                  className='form-control form-textbox'
                  value={formik.values.latitude}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.latitude && formik.touched.latitude ? (
                  <div className='error'>{formik.errors.latitude}</div>
                ) : null}
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>{t('Pincode')}</label>
                <input
                  type='text'
                  name='pincode'
                  id='pincode'
                  className='form-control form-textbox'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pincode}
                />
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>{t('Phone')}</label>
                <input
                  type='text'
                  name='phone_number'
                  id='phone_number'
                  className='form-control form-textbox'
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                />
              </div>

              <div className='col-lg-6 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>
                  {t('Start_Date')}
                  <span className='text-danger'>*</span>
                </label>
                <DatePicker
                  selected={formik.values.startdate}
                  minDate={new Date()}
                  name='startdate'
                  className='form-control form-textbox'
                  placeholderText={t('Start_Date')}
                  onChange={date => {
                    formik.setFieldValue('startdate', date)
                  }}
                  dateFormat='yyyy-MM-dd'
                />
                {formik.touched.startdate && formik.errors.startdate ? (
                  <div className='error'>{formik.errors.startdate}</div>
                ) : null}
              </div>
              <div className='col-lg-6 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>
                  {t('End_Date')} <span className='text-danger'>*</span>
                </label>
                <DatePicker
                  selected={formik.values.enddate}
                  minDate={new Date()}
                  name='enddate'
                  className='form-control form-textbox'
                  placeholderText={t('End_Date')}
                  onChange={date => {
                    formik.setFieldValue('enddate', date)
                  }}
                  dateFormat='yyyy-MM-dd'
                />
                {formik.touched.enddate && formik.errors.enddate ? (
                  <div className='error'>{formik.errors.enddate}</div>
                ) : null}
              </div>
              <div className='col-lg-6 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>
                  {t('Start_Time')}
                  <span className='text-danger'>*</span>
                </label>
                <DatePicker
                  selected={formik.values.startTime}
                  name='startTime'
                  className='form-control form-textbox'
                  placeholderText={t('Start_Time')}
                  onChange={time => {
                    formik.setFieldValue('startTime', time)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  dateFormat='HH:mm'
                  timeFormat='HH:mm'
                />
                {formik.touched.startTime && formik.errors.startTime ? (
                  <div className='error'>{formik.errors.startTime}</div>
                ) : null}
              </div>
              <div className='col-lg-6 col-sm-12 col-md-12 form-group'>
                <label className='label-text'>
                  {t('End_Time')}
                  <span className='text-danger'>*</span>
                </label>
                <DatePicker
                  selected={formik.values.endTime}
                  name='endTime'
                  className='form-control form-textbox'
                  placeholderText={t('End_Time')}
                  onChange={time => {
                    formik.setFieldValue('endTime', time)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  dateFormat='HH:mm'
                  timeFormat='HH:mm'
                />
                {formik.touched.endTime && formik.errors.endTime ? (
                  <div className='error'>{formik.errors.endTime}</div>
                ) : null}
              </div>

              <div className='col-lg-12 col-md-12 col-sm-12 form-group'>
                <label className='label-text'>{t('mobiles')}</label>
                <textarea
                  name='mobiles'
                  id='mobiles'
                  className='form-control form-textbox'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobiles}
                  rows='3'
                  cols='5'
                ></textarea>
              </div>

              <div className='col-lg-12 col-md-12 col-sm-12 form-group'>
                <label className='label-text' style={{ marginBottom: '8px' }}>
                  Select application users to invite
                </label>
                <Select
                  isMulti
                  name='users'
                  options={options}
                  onChange={option => formik.setFieldValue('users', option)}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  value={formik.values.users}
                  placeholder='select Users'
                />
              </div>

              <div className='col-lg-9 col-sm-12 col-md-12 form-group'>
                <label className='label-text mb-2'>{t('Invite')}</label>
                <br />
                {Users?.length === 0 && (
                  <GoogleContacts
                    clientId={CLIENT_ID}
                    buttonText={t('Invite_Gmail')}
                    accessType='online'
                    loginHint='AJDLj6JUa8yxXrhHdWRHIV0S13cA8idSU_-mu-YAYhTjZdYxLk4R59LaF3udDz22tDzyfaWNaqk-aQUFk5D-mPBvtHpHWquEeg'
                    response_type='permission'
                    onSuccess={responseCallback}
                    onFailure={responseCallback}
                    maxResults={999}
                    className={'mt-2'}
                  />
                )}
                {Users?.length > 0 && (
                  <CreatableSelect
                    isMulti
                    onChange={HandleInviteUser}
                    options={Users}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    placeholder={t('Invite_user')}
                  />
                )}
              </div>
              <div className='col-lg-12 col-sm-12 col-md-12'>
                <label className='label-text'>{t('status')}</label>
                <div className='radio'>
                  <input
                    type='radio'
                    name='isPrivate'
                    id='radio2'
                    className='event-status'
                    value={false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultChecked={
                      formik.values.isPrivate === false ? true : false
                    }
                  />
                  <label htmlFor='radio2'>{t('Public')}</label>
                  <input
                    type='radio'
                    id='radio1'
                    name='isPrivate'
                    value={true}
                    onChange={formik.handleChange}
                    className='event-status'
                    onBlur={formik.handleBlur}
                    defaultChecked={
                      formik.values.isPrivate === true ? true : false
                    }
                  />
                  <label htmlFor='radio1'>{t('Private')}</label>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-12 col-lg-4 form-group'>
                <button
                  type='submit'
                  className='pro-choose-file text-uppercase'
                >
                  {event ? t('SAVE') : t('SUBMIT')}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default NewEvent
