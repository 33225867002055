import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GoVerified } from "react-icons/go";
import { MdVerifiedUser } from "react-icons/md";

export default function ProfileDropdown({ handleLogout }) {
  const userData = useSelector((state) => state?.user);
  const { t, i18n } = useTranslation();

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-autoclose-true"
        className="dropdown-profile"
        style={{
          height: "50px",
          width: "50px",
          background: "transparent",
          borderRadius: "80%",
          border: "5px solid #aaa8a8de ",
        }}
        ref={(el) => {
          if (el && userData?.userdetail?.imageURL) {
            el.style.setProperty("border", "none", "important");
          }
        }}
      >
        {userData?.userdetail?.imageURL ? (
          <div>
            <span>
              <img
                alt=""
                src={userData?.userdetail?.imageURL}
                className="rounded-circle"
                width="50px"
                height="50px"
              />
            </span>
            {/* {userData?.userdetail?.isVerified === true && (
              <span>
                <MdVerifiedUser
                  style={{
                    color: "green",
                    height: "30px",
                    width: "30px",
                    position: "relative",
                    top: "10px",
                    right: "28px",
                  }}
                />
              </span>
            )} */}
          </div>
        ) : (
          <img alt="" src="/img/1553336600.png" />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Link className="dropdown-item" to="/e/manage/past">
          {t("Manage Events")}
        </Link>
        <Link className="dropdown-item" to="/profile">
          {t("Manage Profile")}
        </Link>
        <Link className="dropdown-item" to={`/userprofile/${userData?.userId}`}>
          {t("View Profile")}
        </Link>
        <Link className="dropdown-item" to="/" onClick={() => handleLogout()}>
          {t("Logout")}
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}
