import React, { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";
import "./Panchang.scss";
const Panchang = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center details-header">{t("Panchang")}</div>
      <div className="details-body panchang-gradient-1">
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Tithi */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">{t("tithi")}</h6>
              {panchangDetails?.Tithi ? (
                panchangDetails?.Tithi?.map((data, index) => (
                  <Fragment key={index}>
                    {data.includes("upto") ? (
                      <p className="montserrat-font  fw-semibold">
                        {data.split(" upto ")[0]}{" "}
                        <span className="fw-normal">
                          upto {data.split(" upto ")[1]}
                        </span>
                      </p>
                    ) : (
                      <p className="montserrat-font fw-semibold">{data}</p>
                    )}
                  </Fragment>
                ))
              ) : (
                <p className="montserrat-font fw-semibold">None</p>
              )}
            </div>
          </div>
          {/* Nakshatra */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Nakshatra")}</h6>
              {panchangDetails?.Nakshatra ? (
                panchangDetails?.Nakshatra?.map((data, index) => (
                  <Fragment key={index}>
                    {data.includes("upto") ? (
                      <p className="montserrat-font fw-semibold">
                        {data.split(" upto ")[0]}{" "}
                        <span className="fw-normal">
                          upto {data.split(" upto ")[1]}
                        </span>
                      </p>
                    ) : (
                      <p className="montserrat-font fw-semibold">{data}</p>
                    )}
                  </Fragment>
                ))
              ) : (
                <p className="montserrat-font fw-semibold">None</p>
              )}
            </div>
          </div>
          {/* Karana */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">{t("Karana")}</h6>
              {panchangDetails?.Karana ? (
                panchangDetails?.Karana?.map((data, index) => (
                  <Fragment key={index}>
                    {data.includes("upto") ? (
                      <p className="montserrat-font fw-semibold">
                        {data.split(" upto ")[0]}{" "}
                        <span className="fw-normal">
                          {" "}
                          upto {data.split(" upto ")[1]}
                        </span>
                      </p>
                    ) : (
                      <p className="montserrat-font fw-semibold">{data}</p>
                    )}
                  </Fragment>
                ))
              ) : (
                <p className="montserrat-font fw-semibold">None</p>
              )}
            </div>
          </div>
          {/* Yoga */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">{t("Yoga")}</h6>
              {panchangDetails?.Yoga ? (
                panchangDetails?.Yoga?.map((data, index) => (
                  <Fragment key={index}>
                    {data.includes("upto") ? (
                      <p className="montserrat-font fw-semibold">
                        {data.split(" upto ")[0]}{" "}
                        <span className="fw-normal">
                          {" "}
                          upto {data.split(" upto ")[1]}
                        </span>
                      </p>
                    ) : (
                      <p className="montserrat-font fw-semibold">{data}</p>
                    )}
                  </Fragment>
                ))
              ) : (
                <p className="montserrat-font fw-semibold">None</p>
              )}
            </div>
          </div>
          {/* Weekday */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Weekday")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.Weekday
                  ? panchangDetails?.Weekday.map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Paksha */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Paksha")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.Paksha
                  ? panchangDetails?.Paksha.map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Panchang;
