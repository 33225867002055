import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const NivasAndShool = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center details-header">{t("Nivas and Shool")}</div>
      <div className="details-body panchang-gradient-2">
        {" "}
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Homahuti */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Homahuti")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Homahuti"]
                  ? panchangDetails?.["Homahuti"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Agnivasa */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Agnivasa")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Agnivasa"]
                  ? panchangDetails?.["Agnivasa"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Bhadravasa */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Bhadravasa")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Bhadravasa"]
                  ? panchangDetails?.["Bhadravasa"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Shivavasa */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Shivavasa")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Shivavasa"]
                  ? panchangDetails?.["Shivavasa"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Disha Shool */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Disha Shool")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Disha Shool"]
                  ? panchangDetails?.["Disha Shool"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Chandra Vasa */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Chandra Vasa")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Chandra Vasa"]
                  ? panchangDetails?.["Chandra Vasa"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Rahu Vasa */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Rahu Vasa")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Rahu Vasa"]
                  ? panchangDetails?.["Rahu Vasa"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Kumbha Chakra */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Kumbha Chakra")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Kumbha Chakra"]
                  ? panchangDetails?.["Kumbha Chakra"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NivasAndShool;
