import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { BsGoogle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { getLoginUserData } from "../../store/actions/authActions";

function GoogleLog({ lng, lat, handleCloseLogin }) {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const dispatch = useDispatch();

  const responseGoogle = async (response) => {
    if (!response?.error) {
      handleCloseLogin();
      localStorage.setItem("accessToken", response?.accessToken);
      const UserDetail = {
        userEmail: response?.profileObj?.email,
        language: localStorage.getItem("REACT_APP_LANGUAGE"),
        loginType: "1",
        userName: response?.profileObj?.email?.split("@")[0],
        name: response?.profileObj?.name,
        imageURL: response?.profileObj?.imageUrl,
        firstName: response?.profileObj?.givenName,
        lastName: response?.profileObj?.familyName,
        location: {
          coordinates: [lng, lat],
        },
        checkuser: true,
      };
      dispatch(getLoginUserData(UserDetail));
    }
  };

  useEffect(() => {
    function start() {
      window.gapi.client.init({
        clientId: CLIENT_ID,
        scope: "email",
      });
    }
    window.gapi.load("client:auth2", start);
  }, []);

  return (
    <>
      <GoogleLogin
        clientId={CLIENT_ID}
        render={(renderProps) => (
          <div className="form-group text-center">
            <button
              type="submit"
              className="login_google"
              name="booking"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <span>
                <BsGoogle style={{ height: "30px", width: "30px" }} />
              </span>
              <span style={{ paddingLeft: "10px", fontWeight: "700" }}>
                Log in with Google
              </span>
            </button>
          </div>
        )}
        buttonText="Login"
        onSuccess={(e) => responseGoogle(e)}
        onFailure={(e) => responseGoogle(e)}
        cookiePolicy={"single_host_origin"}
      />
    </>
  );
}

export default GoogleLog;
