import React, { useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const ChandrabalamAndTarabalam = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center details-header">
        {`${t("Chandrabalam")} ${t("and")} ${t("Tarabalam")}`}
      </div>
      <div className="details-body panchang-gradient-4">
        {" "}
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Chandrabalam */}
          <div className="col-6">
            {panchangDetails?.Chandrabalam ? (
              <div>
                {panchangDetails?.Chandrabalam?.map((details, index) => (
                  <Fragment key={index}>
                    <h6 className="montserrat-font text-white">
                      {details?.upto}
                    </h6>
                    <div className="row">
                      {details?.values.map((value, index) => (
                        <p
                          key={index}
                          className="col-6 col-md-4 col-lg-4 montserrat-font fw-semibold"
                        >
                          {value}
                        </p>
                      ))}
                    </div>
                  </Fragment>
                ))}
              </div>
            ) : (
              <>
                <p className="montserrat-font text-white text-center">
                  {t("Chandrabalam")}
                </p>
                <p className="montserrat-font fw-semibold text-center">None</p>
              </>
            )}
          </div>
          {/* Tarabalam */}
          <div className="col-6">
            {panchangDetails?.Tarabalam ? (
              <div>
                {panchangDetails?.Tarabalam?.map((details, index) => (
                  <Fragment key={index}>
                    <h6 className="montserrat-font text-white">
                      {details?.upto}
                    </h6>
                    <div className="row">
                      {details?.values.map((value, index) => (
                        <p
                          key={index}
                          className="col-6 col-md-4 col-lg-4 montserrat-font fw-semibold"
                        >
                          {value}
                        </p>
                      ))}
                    </div>
                  </Fragment>
                ))}
              </div>
            ) : (
              <>
                <p className="montserrat-font text-white text-center">
                  {t("Tarabalam")}
                </p>
                <p className="montserrat-font fw-semibold text-center">None</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChandrabalamAndTarabalam;
