import React from "react";
import { useTranslation } from "react-i18next";

const PanchangHeading = () => {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "7.32vw" }}>
      <h2 className="route-heading">{t("Panchang")}</h2>
      <p className="montserrat-font" style={{ textAlign: "justify" }}>
        {t("Panchang Heading")}
      </p>
    </div>
  );
};

export default PanchangHeading;
