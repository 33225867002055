import axios from "axios";
// const api_key = process.env.API_KEY_HEADER;
const API_URL = process.env.REACT_APP_ENDPOINT;

class HttpService {
  get(url, params = {}) {
    return call("GET", url, params);
  }

  post(url, params = {}, payload = {}) {
    return call("POST", url, params, payload);
  }

  delete(url, params = {}) {
    return call("DELETE", url, params);
  }

  put(url, params = {}, payload = {}) {
    return call("PUT", url, params, payload);
  }
}

function call(method, URL, params, payload = {}) {
  const opts = {
    method,
    url: API_URL + URL,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (params) opts.params = params;
  if (payload) opts.data = payload;
  // opts.headers.Authorize = `Bearer ${localStorage.getItem("keycloaktoken")}`;
  return axios(opts);
}

axios.interceptors.request.use(
  function (config) {
    // document.getElementById("loading-indicator").classList.remove("d-none");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (res) => {
    // document.getElementById("loading-indicator").classList.add("d-none");
    return res;
  },
  (err) => {
    if (err?.response?.data) {
      throw new Error(err?.response?.data);
    } else {
      switch (err && err.response && err.response.status) {
        case 400:
          throw new Error("Bad request");
        case 401:
          throw new Error("Unauthorized");
        case 403:
          throw new Error("Forbidden");
        case 404:
          throw new Error("Not Found");
        case 500:
          throw new Error("Internal Server error");
        case 502:
          throw new Error("Bad Gateway");
        default:
          throw new Error("Server Side Error");
      }
    }
  }
);

export default new HttpService();
