import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginModal from "../Components/Modal/Login/LoginModal";
import { showSearch } from "../store/actions/eventActions";
import { getFollowUser } from "../store/actions/userActions";
import "./search.scss";

function Search({ setSearch, searchBy }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchResult = useSelector(showSearch);
  const searchUser = useSelector((state) => state?.SearchUser?.searchUser);
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);

  function handleFollow(followerId) {
    const data = {
      followingId: userData?.userId,
      followerId: followerId,
    };
    dispatch(getFollowUser(data));
  }
  console.log(searchUser);
  return (
    <>
      <div className="overlays">
        <div className="d-flex align-items-center justify-content-between search-header">
          <span>{searchBy}</span>
          <div onClick={setSearch} style={{ cursor: "pointer" }}>
            <MdOutlineClose />
          </div>
        </div>

        {searchResult.length !== 0 && searchBy === t("Events") ? (
          searchResult?.map((value, index) => {
            return (
              <div
                className="row flex-nowrap p-0 m-0"
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/event/${value?._id}`);
                  setSearch();
                }}
              >
                <div className="col-md-3 m-0 p-0">
                  <div
                    className="card"
                    style={{ width: "100%", border: "none" }}
                  >
                    <div className="card-body">
                      <img
                        src={
                          value?.imageURL
                            ? value?.imageURL
                            : "/img/1553336600.png"
                        }
                        alt=""
                        style={{
                          cursor: "pointer",
                          width: "80px",
                          borderRadius: "10px",
                          height: "80px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="card border-0">
                    <div className="card-body">
                      <h5 className="card-title" style={{ fontSize: "14px" }}>
                        <span className="me-1">
                          {moment(value?.startDate).format("ddd, MMM DD, YYYY")}
                        </span>
                        AT
                        <br />
                        <div className="pt-1">
                          {moment(value?.startDate).format("hh:mm A")}
                        </div>
                      </h5>
                      <div
                        className="card-text content-link"
                        style={{ fontSize: "14px" }}
                      >
                        {value?.title}
                      </div>
                      <div
                        className="card-text pt-1"
                        style={{ fontSize: "14px" }}
                      >
                        {value?.address}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : searchBy === t("Events") ? (
          <div className="no_result">
            No result found! Try with different keywords!
          </div>
        ) : (
          ""
        )}
        {searchUser?.length !== 0 && searchBy === t("Users") ? (
          searchUser?.map((value, index) => {
            const followIndex =
              value?.followers !== undefined &&
              value?.followers?.findIndex(
                (data) => data.userId === userData?.userId
              );
            return (
              <div className="row p-0 m-0 mb-3" key={index}>
                <div className="col-4">
                  <div
                    className="card text-center"
                    style={{ width: "100%", border: "none" }}
                  >
                    <div className="card-body p-0">
                      <img
                        src={
                          value?.imageURL
                            ? value?.imageURL
                            : "/default/user.png"
                        }
                        alt="user_img"
                        style={{
                          width: "80px",
                          height: "80px",
                          cursor: "pointer",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          navigate(`/userprofile/${value?._id}`);
                          setSearch();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="card border-0">
                    <div className="card-body p-0">
                      <h5 className="card-title" style={{ fontSize: "14px" }}>
                        <div className="detail">
                          <div
                            className="Name"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/userprofile/${value?._id}`);
                              setSearch();
                            }}
                          >
                            {value?.firstName} {value?.lastName}
                          </div>
                          <div className="mt-1">
                            <span>
                              {value?.city}, {value?.country}
                            </span>
                          </div>
                          {value?._id !== userData?.userId ? (
                            <div className="mt-3 d-flex align-items-center">
                              <button
                                className="follow me-3"
                                size="sm"
                                onClick={() =>
                                  !token
                                    ? setShowLogin(true)
                                    : handleFollow(value?._id)
                                }
                              >
                                {followIndex === -1 ? "Follow" : "Unfollow"}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : searchBy === t("Users") ? (
          <div className="no_result">
            No result found! Try with different keywords!
          </div>
        ) : (
          ""
        )}
      </div>
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
      />
    </>
  );
}
export default Search;
