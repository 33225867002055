import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../../pages/UserProfile/userProfile";
import { getFollowUser } from "../../../store/actions/userActions";
import LoginModal from "../Login/LoginModal";
import "./userList.scss";

function UserList({ show, close, userlist }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const [userId, setUserId] = useState("");

  function handleFollow(followerId) {
    const data = {
      followingId: userData?.userId,
      followerId: followerId,
    };
    dispatch(getFollowUser(data));
  }

  return (
    <>
      <div>
        <Modal show={show} onHide={close}>
          <Modal.Header
            closeButton
            style={{ fontSize: "10px", borderBottom: "none" }}
            className="close"
          ></Modal.Header>
          <Modal.Body className="save-body">
            <div className="modal-title">Event Participant</div>
            {userlist?.length > 0 ? (
              userlist?.map((value, index) => {
                let indexfollow = userData?.userdetail?.followings?.findIndex(
                  (data) => data?.userId === value?.usersId?._id
                );
                return (
                  <div
                    className="row"
                    key={index}
                    style={{ marginBottom: "-10px" }}
                  >
                    <div className="col-md-3">
                      <div
                        className="card"
                        style={{ width: "100%", border: "none" }}
                      >
                        <div className="card-body p-0">
                          <img
                            src={
                              value?.usersId?.imageURL
                                ? value?.usersId?.imageURL
                                : "/default/user.png"
                            }
                            alt="image"
                            style={{
                              width: "80px",
                              height: "80px",
                              margin: "22px",
                              cursor: "pointer",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              setUserId(value?.usersId?._id);
                              navigate(`/userprofile/${value?.usersId?._id}`);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 d-flex align-items-center">
                      <div
                        className="card"
                        style={{ width: "100%", border: "none" }}
                      >
                        <div className="card-body p-0">
                          <h5
                            className="card-title"
                            style={{ fontSize: "14px" }}
                          >
                            <div className="detail">
                              {value?.usersId?.firstName &&
                                value?.usersId?.lastName && (
                                  <div
                                    className="mt-1 Name"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setUserId(value?.usersId?._id);
                                      navigate(
                                        `/userprofile/${value?.usersId?._id}`
                                      );
                                    }}
                                  >
                                    {value?.usersId?.firstName}
                                    {value?.usersId?.lastName}
                                  </div>
                                )}
                              <div className="mt-1">
                                <span>
                                  {value?.usersId?.city}
                                  {value?.usersId?.country}
                                </span>
                              </div>
                            </div>
                            {value?.usersId?._id !== userData?.userId &&
                            token ? (
                              <div className="mt-2 d-flex align-items-center">
                                <button
                                  className="follow me-3"
                                  size="sm"
                                  onClick={() =>
                                    !token
                                      ? setShowLogin(true)
                                      : handleFollow(value?.usersId._id)
                                  }
                                >
                                  {userData &&
                                  userData?.userdetail &&
                                  userData?.userdetail?.followings[indexfollow]
                                    ?.userId === value?.usersId?._id
                                    ? "Unfollow"
                                    : "Follow"}
                                </button>
                              </div>
                            ) : null}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="row nodata">
                <div className="col-md-10 col-sm-10 col-lg-10  nodatawrapper">
                  <h3 className="noDataContent m-0">
                    There is no Participant of this Event.
                  </h3>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
      />
      {userId && <UserProfile />}
    </>
  );
}
export default UserList;
