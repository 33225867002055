import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEventApi,
  getEventListApi,
  showEvents,
} from "../../../store/actions/eventActions";
import "./events.scss";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import NewEvent from "../../../Components/Modal/newEvent/newEvent";

function Event() {
  const userData = useSelector((state) => state?.user?.userdetail);
  const events = useSelector(showEvents);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showCreate, setShowCreate] = useState(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);
  const [id, setId] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    dispatch(getEventListApi(userData?._id));
  }, []);

  function handleDelete(id, title) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete" + " " + title + " " + "event ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteEventApi(id));
      }
    });
  }

  return (
    <>
      <div>
        <div className="row d-flex flex-row-reverse m-0 p-0">
          <button className="add_new_event" onClick={() => handleShowCreate()}>
            {t("Add_New_Event")}
          </button>
        </div>
        {events?.data?.data?.length > 0 ? (
          <Table responsive style={{ borderCollapse: "none" }}>
            <thead>
              <tr className="text-capitalize">
                <th>#</th>
                <th>{t("Image")}</th>
                <th>{t("Created_Date")}</th>
                <th>{t("title")}</th>
                <th>{t("description")}</th>
                <th>{t("Approval_Status")}</th>
                <th></th>
              </tr>
            </thead>
            {events?.data?.data?.length > 0 &&
              events?.data?.data?.map((value, index) => {
                return (
                  <tbody className="align-middle" key={index}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          src={value?.imageURL}
                          alt=""
                          height={80}
                          width={80}
                        />
                      </td>
                      <td>
                        <span>
                          {moment(value?.createdAt).format("DD/MM/YYYY")}
                        </span>
                        <span className="ps-2">
                          {moment(value?.createdAt).format("hh:mm:ss")}
                        </span>
                      </td>
                      <td>{value?.title}</td>
                      <td>{value?.description}</td>
                      <td>
                        {value?.eventStatus === "APPROVED" ? (
                          <button className="approved_btn">
                            {value?.eventStatus}
                          </button>
                        ) : (
                          <button className="pending_btn">
                            {value?.eventStatus}
                          </button>
                        )}
                      </td>
                      <td>
                        <div>
                          <span>
                            <button
                              className="edit_btn"
                              onClick={() => {
                                handleShowCreate();
                                setId(value?._id);
                                setSelectedEvent(value);
                              }}
                            >
                              Edit
                            </button>
                          </span>
                          <span>
                            <button
                              className="delete_btn"
                              onClick={() =>
                                handleDelete(value?._id, value?.title)
                              }
                            >
                              Detete
                            </button>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </Table>
        ) : (
          <div className="notFound"> No Events Found </div>
        )}
      </div>
      {showCreate && (
        <NewEvent
          show={showCreate}
          close={handleCloseCreate}
          event={selectedEvent ? selectedEvent : ""}
          setSelectedEvent={setSelectedEvent}
        />
      )}
    </>
  );
}

export default Event;
