import React, { useEffect } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./i18n";
import Events from "./Components/Events/events";
import Home from "./pages/Home/Home";
import Signin from "./Components/Signin/signIn";
import Signup from "./Components/SignUp/signUp";
import { Route, Routes } from "react-router-dom";
import EventView from "./Components/EventView/eventView";
import "./styles/global.scss";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/Footer";
import Profile from "./pages/Profile/profile";
import { useSelector } from "react-redux";
import PrivateRoute from "./Components/routes/privateRoute";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import UserProfile from "./pages/UserProfile/userProfile";
import Suggested_Account from "./Components/suggested_account/suggested_account";
import ManageEvents from "./Components/ManageEvents/Events";
import ProtectedRoute from "./Components/routes/protectedRoute";
import Horoscope from "./Components/Horoscope/Horoscope";
import PanchangHome from "./Components/Panchang/PanchangHome";
import Kundli from "./Components/Kundli/Kundli";
import Articles from "./Components/Articles/Articles";
import SingleArticle from "./Components/Articles/SingleArticle";
import SingleEvent from "./Components/Events/SingleEvent/SingleEvent";
import Matrimony from "./Components/MatrimonyPackage/Matrimony";
function App() {
  const userData = useSelector((state) => state?.user);

  const paramsdata = "en.localhost";

  useEffect(() => {
    const currentLng = paramsdata?.split(".");
    // localStorage.setItem("lang", currentLng[0]);
  }, []);

  return (
    <div className="app">
      <Suspense fallback={null}>
        <Header />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <PrivateRoute
                isAuthenticated={userData?.isAuthenticated}
                newUser={userData?.newUser}
              />
            }
          >
            <Route exact path="/" element={<Home />} />
            <Route exact path="/signin" element={<Signin />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/event" element={<Events />} />
            {/* <Route exact path="/event/:id" element={<EventView />} />     */}
            <Route exact path="/event/:id" element={<SingleEvent />} />
            <Route exact path="/userprofile/:id" element={<UserProfile />} />
            <Route exact path="/suggested" element={<Suggested_Account />} />
            <Route exact path="/horoscope" element={<Horoscope />} />
            <Route exact path="/panchang" element={<PanchangHome />} />
            <Route exact path="/kundli" element={<Kundli />} />
            <Route exact path="/articles" element={<Articles />} />
            <Route exact path="/article/:id" element={<SingleArticle />} />
          </Route>
          <Route exact path="/matrimony" element={<Matrimony />} />
          <Route
            element={
              <ProtectedRoute isAuthenticated={userData?.isAuthenticated} />
            }
          >
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/e/manage/:events" element={<ManageEvents />} />
          </Route>
        </Routes>
        <Footer />
      </Suspense>
      <ToastContainer />
    </div>
  );
}

export default App;
