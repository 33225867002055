import React, { useState } from "react";
import AscendantResult from "./AscendantResult/AscendantResult";
import NumeroTable from "./NumeroTable/NumeroTable";
import SuggestionAndRemadies from "./SuggestionAndRemadies/SuggestionAndRemadies";
import KalaSarpaDosha from "./KalaSarpaDosha/KalaSarpaDosha";
import MatchMaking from "./MatchMaking/MatchMaking";
import { useTranslation } from "react-i18next";
import "./KundiButtons.scss";

const KundliButtons = () => {
  const { t } = useTranslation();
  const [buttonName, setButtonName] = useState("ascendantResult");
  const [ascendantResultClicked, setAscendantResultClicked] = useState(true);
  const [numeroTableClicked, setNumeroTableClicked] = useState(false);
  const [suggestionAndRemadiesClicked, setSuggestionAndRemadiesClicked] =
    useState(false);
  const [kalaSarpaDoshaClicked, setKalaSarpaDoshaClicked] = useState(false);
  const [matchMakingClicked, setMatchMakingClicked] = useState(false);

  const handleButtons = (setButtonClicked, buttonName) => {
    setAscendantResultClicked(false);
    setNumeroTableClicked(false);
    setSuggestionAndRemadiesClicked(false);
    setKalaSarpaDoshaClicked(false);
    setMatchMakingClicked(false);
    setButtonClicked(true);
    setButtonName(buttonName);
  };
  return (
    <>
      <div className="d-flex flex-row gap-2 gap-lg-3 flex-wrap flex-md-nowrap flex-lg-nowrap">
        <button
          type="button"
          onClick={() =>
            handleButtons(setAscendantResultClicked, "ascendantResult")
          }
          className={`kundli-button montserrat-font fw-bolder mx-auto w-100 ${
            buttonName === "ascendantResult"
              ? "kundli-button-clicked"
              : "kundli-button-not-clicked"
          }`}
          // style={{ fontSize: "1.47vw" }}
        >
          {t("ascendant")}
        </button>
        <button
          type="button"
          onClick={() => handleButtons(setNumeroTableClicked, "numeroTable")}
          className={`kundli-button montserrat-font fw-bolder mx-auto w-100 ${
            buttonName === "numeroTable"
              ? "kundli-button-clicked"
              : "kundli-button-not-clicked"
          }`}
          // style={{ fontSize: "1.47vw" }}
        >
          {t("Numero Table")}
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(
              setSuggestionAndRemadiesClicked,
              "suggestionAndRemadies"
            )
          }
          className={`kundli-button montserrat-font fw-bolder mx-auto w-100 ${
            buttonName === "suggestionAndRemadies"
              ? "kundli-button-clicked"
              : "kundli-button-not-clicked"
          }`}
          // style={{ fontSize: "1.47vw" }}
        >
          {t("Suggestion and Remadies")}
        </button>
        <button
          type="button"
          onClick={() =>
            handleButtons(setKalaSarpaDoshaClicked, "kalaSarpaDosha")
          }
          className={`kundli-button montserrat-font fw-bolder mx-auto w-100 ${
            buttonName === "kalaSarpaDosha"
              ? "kundli-button-clicked"
              : "kundli-button-not-clicked"
          }`}
          // style={{ fontSize: "1.47vw" }}
        >
          {t("Kalasarpa Dosha")}
        </button>
        <button
          type="button"
          onClick={() => handleButtons(setMatchMakingClicked, "matchMaking")}
          className={`kundli-button montserrat-font fw-bolder mx-auto w-100 ${
            buttonName === "matchMaking"
              ? "kundli-button-clicked"
              : "kundli-button-not-clicked"
          }`}
          // style={{ fontSize: "1.47vw" }}
        >
          {t("Matchmaking")}
        </button>
      </div>
      {ascendantResultClicked && <AscendantResult />}
      {numeroTableClicked && <NumeroTable />}
      {suggestionAndRemadiesClicked && <SuggestionAndRemadies />}
      {kalaSarpaDoshaClicked && <KalaSarpaDosha />}
      {matchMakingClicked && <MatchMaking />}
    </>
  );
};

export default KundliButtons;
