import React, { useContext } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Autocomplete from "react-google-autocomplete";
import { KundliContext } from "./Kundli";
import { useTranslation } from "react-i18next";
const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const KundliInput = () => {
  const { formik, setLocationData } = useContext(KundliContext);
  const { t } = useTranslation();
  const { data: years } = useQuery([`years`], () =>
    axios.get("/jsons/years.json").then((data) => data.data)
  );

  // const [name, setName] = useState("");
  // const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const matchedInfo = storedUserInfo?.find((info) => info.name === name);

  return (
    <form onSubmit={formik.handleSubmit} className="d-grid gap-4 pt-3 pt-lg-5">
      <div className="row g-2 g-lg-5">
        {/* Name Input */}
        <div className="col-6 col-lg-4 kundli-input-div">
          <label htmlFor="name" className="form-label montserrat-font ">
            {t("Name")}
          </label>
          <input
            type="text"
            className="form-control montserrat-font"
            id="name"
            name="name"
            placeholder={t("Enter Your Name")}
            onChange={(e) => {
              // setName(e.target.value);
              formik.handleChange(e);
            }}
            // defaultValue={matchedInfo ? matchedInfo.name : formik.values?.name}
            // value={matchedInfo ? matchedInfo.name : formik.values?.name}
            value={formik.values?.name}
          />
          {formik.errors.name ? (
            <small className="text-danger fw-bold">{formik.errors.name}</small>
          ) : null}
        </div>
        {/* Gender Input */}
        <div className="col-6 col-lg-4 kundli-input-div">
          <label htmlFor="gender" className="form-label montserrat-font">
            {t("Gender")}
          </label>
          <select
            className="form-select montserrat-font pt-0"
            id="gender"
            name="gender"
            onChange={formik.handleChange}
            // value={matchedInfo ? matchedInfo.gender : formik.values.gender}
            value={formik.values.gender}
          >
            <option>{t("Male")}</option>
            <option>{t("Female")}</option>
            <option>{t("Other")}</option>
          </select>
        </div>
        {/*Date of Birth */}
        <div className="col-lg-4">
          <h6 className="label montserrat-font my-1 mb-lg-3">
            {t("Date of Birth")}
          </h6>
          <div className="row g-3">
            <div className="col-4">
              <div className="form-floating kundli-input-div">
                <select
                  className="form-select montserrat-font"
                  id="day"
                  name="day"
                  onChange={formik.handleChange}
                  // value={matchedInfo ? matchedInfo.day : formik.values.day}
                  value={formik.values.day}
                >
                  {days?.map((day, index) => (
                    <option key={index}>{day}</option>
                  ))}
                </select>
                <label htmlFor="day">Day</label>
              </div>
            </div>
            <div className="col-4">
              <div className="form-floating kundli-input-div">
                <select
                  className="form-select montserrat-font"
                  id="month"
                  name="month"
                  onChange={formik.handleChange}
                  // value={matchedInfo ? matchedInfo.month : formik.values.month}
                  value={formik.values.month}
                >
                  {months?.map((month, index) => (
                    <option key={index}>{month}</option>
                  ))}
                </select>
                <label htmlFor="month">Month</label>
              </div>
            </div>
            <div className="col-4">
              <div className="form-floating kundli-input-div">
                <select
                  className="form-select montserrat-font"
                  id="year"
                  name="year"
                  onChange={formik.handleChange}
                  // value={matchedInfo ? matchedInfo.year : formik.values.year}
                  value={formik.values.year}
                >
                  {years?.map((year, index) => (
                    <option key={index}>{year.year}</option>
                  ))}
                </select>
                <label htmlFor="year">Year</label>
              </div>
            </div>
          </div>
        </div>
        {/*Time of Birth */}
        <div className="col-lg-4">
          <h6 className="label montserrat-font my-1 mb-lg-3">
            {t("Time of Birth")}
          </h6>
          <div className="row g-3">
            <div className="col-4">
              <div className="form-floating kundli-input-div">
                <select
                  className="form-select montserrat-font"
                  id="hour"
                  name="hour"
                  onChange={formik.handleChange}
                  // value={
                  //   matchedInfo
                  //     ? matchedInfo.hour > 12
                  //       ? matchedInfo.hour - 12
                  //       : matchedInfo.hour
                  //     : formik.values.hour
                  // }
                  value={formik.values.hour}
                >
                  {hours?.map((hour, index) => (
                    <option key={index}>{hour}</option>
                  ))}
                </select>
                <label htmlFor="hour">Hour</label>
              </div>
            </div>
            <div className="col-4">
              <div className="form-floating kundli-input-div">
                <select
                  className="form-select montserrat-font"
                  id="min"
                  name="min"
                  onChange={formik.handleChange}
                  // value={matchedInfo ? matchedInfo.min : formik.values.min}
                  value={formik.values.min}
                >
                  {minutes?.map((minute, index) => (
                    <option key={index}>{minute}</option>
                  ))}
                </select>
                <label htmlFor="min">Minute</label>
              </div>
            </div>
            <div className="col-4">
              <div className="form-floating kundli-input-div">
                <select
                  className="form-select montserrat-font"
                  id="clockTime"
                  name="clockTime"
                  onChange={formik.handleChange}
                  // value={
                  //   matchedInfo
                  //     ? matchedInfo.clockTime
                  //     : formik.values.clockTime
                  // }
                  value={formik.values.clockTime}
                >
                  <option>AM</option>
                  <option>PM</option>
                </select>
                <label htmlFor="clockTime">AM / PM</label>
              </div>
            </div>
          </div>
        </div>
        {/*Birth Place*/}
        <div className="col-6 col-lg-4 kundli-input-div">
          <label htmlFor="place" className="form-label montserrat-font">
            {t("Birth Place")}
          </label>
          <Autocomplete
            type="text"
            className="form-control montserrat-font"
            id="place"
            name="place"
            placeholder={t("Enter Your Birth Place")}
            apiKey={googleMapsApiKey}
            onChange={formik.handleChange}
            // defaultValue={matchedInfo ? matchedInfo.place : formik.values.place}
            // value={matchedInfo ? matchedInfo.place : formik.values.place}
            value={formik.values.place}
            onPlaceSelected={(place) => {
              setLocationData(place);
              formik.setFieldValue("place", place.formatted_address);
            }}
          />
          {formik.errors.place ? (
            <small className="text-danger fw-bold">{formik.errors.place}</small>
          ) : null}
        </div>
        {/* Submit Button */}
        <div
          className={`col-6 col-lg-4 kundli-input-div d-flex ${
            formik?.isValid ? "align-items-end" : "align-items-center"
          }`}
        >
          <button
            style={{
              backgroundColor: "#e28e00",
            }}
            className="btn montserrat-font text-white w-100"
            type="submit"
            disabled
            id="submit-button"
          >
            {t("Get Kundli")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default KundliInput;
