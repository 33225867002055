import React, { useEffect, useState } from "react";
// import "./header.scss";
import { RiSearchLine } from "react-icons/ri";
import { BiCurrentLocation } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import LoginModal from "../Modal/Login/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";
import Search from "../../search/search";
import { findEventApi, nearByEventApi } from "../../store/actions/eventActions";
import { usePlacesWidget } from "react-google-autocomplete";
import ProfileDropdown from "../ProfileDropdown";
import { authAction } from "../../store/slice/authSlice";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Sidemenudrawer from "../SideMenuDrawer";
import { getSearchUserData } from "../../store/actions/userActions";
import { toast } from "react-toastify";
import NewEvent from "../Modal/newEvent/newEvent";
import CustomLink from "./CustomLink";

function Header() {
  const logo = localStorage.getItem("REACT_APP_LOGO");
  const limit = "25";
  const { t, i18n } = useTranslation();
  const [chanSearch, setchanSearch] = useState("Events");
  const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [tops, setTops] = useState(0);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);

  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const [search, setSearch] = useState("");

  const [showsearch, setShowSearch] = useState(false);
  const handleCloseSearch = () => setShowSearch(false);
  // const handleShowSearch = () => setShowSearch(true);

  const [showCreate, setShowCreate] = useState(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);
  const userData = useSelector((state) => state?.user);
  const [fOptions, setFOptions] = useState([]);
  const token = localStorage.getItem("token");
  const l = localStorage.getItem("REACT_APP_LANGUAGE");
  const selectedLang = localStorage.getItem("lang");
  const options = [
    { value: "en", label: t("English") },
    { value: "gu", label: t("Gujarati") },
    { value: "hi", label: t("Hindi") },
    { value: "mr", label: t("Marathi") },
    { value: "kn", label: t("Kannada") },
    { value: "ta", label: t("Tamil") },
    { value: "bn", label: t("Bengali") },
    { value: "ml", label: t("Malayalam") },
    { value: "te", label: t("Telugu") },
    { value: "or", label: t("Odia") },
  ];

  useEffect(() => {
    if (l.toLowerCase() === "en" || l.toLowerCase() === "ms") {
      setFOptions(options);
    } else {
      setFOptions(
        options.filter((options) => {
          return options.value === l.toLowerCase() || options.value === "en";
        })
      );
    }
  }, []);

  const HandleLanguage = (event) => {
    localStorage.setItem("lang", event.value);
    i18n.changeLanguage(event.value);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLang ? selectedLang : l.toLowerCase());
  }, []);

  useEffect(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          if (position?.coords?.latitude && position?.coords?.longitude) {
            localStorage.setItem(
              "location",
              JSON.stringify({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
          }
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  }, []);

  function handleSearch(query) {
    setShowSearch(true);
    if (query.length > 2) {
      const searchdata = {
        searchQuery: query,
        language: localStorage.getItem("REACT_APP_LANGUAGE"),
      };
      dispatch(findEventApi(searchdata));
    }
    if (query.length > 2) {
      const searchUser = {
        name: query,
        lang: localStorage.getItem("REACT_APP_LANGUAGE"),
      };
      dispatch(getSearchUserData(searchUser));
    }
  }

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_KEY,
    onPlaceSelected: async (place) => {
      sessionStorage.setItem("temp_location_name", place?.formatted_address);
      await sessionStorage.setItem(
        "tempLocation",
        JSON.stringify({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        })
      );
      const data = {
        eventStatus: "APPROVED",
        distance: 300000,
        language: userData?.userdetail?.lang
          ? userData?.userdetail?.language
          : localStorage.getItem("REACT_APP_LANGUAGE"),
        nearBy: true,
        lat: place?.geometry?.location?.lat(),
        long: place?.geometry?.location?.lng(),
      };
      dispatch(nearByEventApi(data));
    },
  });

  useEffect(() => {
    if ((lat, lng)) {
      Geocode.setApiKey(GOOGLE_MAPS_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("es");
      Geocode.setLocationType("ROOFTOP");
      Geocode.enableDebug();
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
        },
        (error) => {}
      );
    }
  }, [lat, lng, GOOGLE_MAPS_KEY]);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const cls = visible ? "visible" : "hidden";

  function handleLogout() {
    dispatch(authAction.userLogout());
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    sessionStorage.removeItem("tempLocation");
    sessionStorage.removeItem("temp_location_name");
  }

  const completeProfileToast = () => {
    toast.warning("Please Complete Your Profile First", { theme: "colored" });
  };

  const handleTruncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };

  return (
    <div
      className={`Main-header container-fluid fixed-top ${cls} p-0`}
      style={{ padding: 0 }}
      onScroll={() => setTops(-70)}
    >
      <div
        className="header header-mains-menu d-flex align-items-center px-2"
        id="header-scroll"
      >
        <div className="header">
          <div className="row p-0 m-0 d-flex flex-nowrap common justify-content-between align-items-center">
            <div className="col-xl-auto w-auto col-md-auto col-sm-auto logo p-0">
              <span
                onClick={() => {
                  navigate("/");
                }}
              >
                <img alt="" src={logo} className="logo_img"></img>
              </span>
            </div>
            <div className="col-xl-auto col-md-auto col-sm-auto p-0 d-none d-xl-block text-center header-search p-0">
              <div className="search w-100">
                <input
                  type="text"
                  name="search"
                  className="form-control search-box ps-5"
                  placeholder={t("search")}
                  id="tags"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    handleSearch(e.target.value);
                  }}
                />
                <span className="btn search-button searchicon">
                  <RiSearchLine />
                </span>
                <span className="vertical" />
                <div className="action">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={t(chanSearch)}
                  >
                    <Dropdown.Item onClick={() => setchanSearch(t("Users"))}>
                      {t("Users")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setchanSearch(t("Events"))}>
                      {t("Events")}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>
            <div className="col-xl-auto col-md-auto col-sm-auto d-none d-xl-block p-0">
              <div
                className="main-menu text-capitalize d-flex align-items-center ps-0"
                id="menu"
              >
                <li className="menu-li">
                  <CustomLink
                    as={Link}
                    to="/horoscope"
                    className="browse-event"
                  >
                    {t("Horoscope")}
                  </CustomLink>
                </li>
                <li className="menu-li">
                  <CustomLink as={Link} to="/panchang" className="browse-event">
                    {t("Panchang")}
                  </CustomLink>
                </li>
                <li className="menu-li">
                  <CustomLink as={Link} to="/kundli" className="browse-event">
                    {t("Kundli")}
                  </CustomLink>
                </li>
                <li className="menu-li">
                  <CustomLink as={Link} to="/articles" className="browse-event">
                    {t("Articles")}
                  </CustomLink>
                </li>
                <li className="menu-li">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      style={{
                        background: "transparent",
                        border: "0px",
                        color: "black",
                      }}
                    >
                      {t("Events")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          !userData?.newUser || !token
                            ? navigate("/event")
                            : token
                            ? completeProfileToast()
                            : ""
                        }
                      >
                        {t("Events")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          token
                            ? !userData?.newUser
                              ? setShowCreate(true)
                              : completeProfileToast()
                            : setShowLogin(true)
                        }
                      >
                        {t("Create Events")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                    {/* <DropdownButton id="dropdown-basic-button border-0 h-0 w-0"></DropdownButton> */}
                  </Dropdown>

                  {/* <CustomLink
                    as={Link}
                    to="/event"
                    onClick={() =>
                      userData?.newUser === false || !token
                        ? navigate("/event")
                        : token
                        ? completeProfileToast()
                        : ""
                    }
                    // className="browse-event"
                  >
                    {t("Browse Events")}
                  </CustomLink>{" "} */}
                </li>
                <li className="menu-li">
                  <CustomLink
                    as={Link}
                    to="/matrimony"
                    className="browse-event"
                  >
                    {t("Matrimony")}
                  </CustomLink>
                </li>
                <li className="menu-li">
                  {!token && !userData?.userdetail?.imageURL ? (
                    <CustomLink
                      to=""
                      className="signin-btn"
                      onClick={() => handleShowLogin()}
                    >
                      {t("Sign In")}
                    </CustomLink>
                  ) : (
                    <ProfileDropdown handleLogout={() => handleLogout()} />
                  )}
                </li>
              </div>
            </div>
            <div className="col-xl-auto col-md-auto col-sm-auto w-auto d-none d-md-flex d-xl-flex p-0 m-0">
              <span className="location">
                <BiCurrentLocation />
              </span>
              <input
                className="form-control search-box ps-5 loc"
                ref={ref}
                type="text"
                defaultValue={
                  sessionStorage.getItem("temp_location_name")
                    ? sessionStorage.getItem("temp_location_name")
                    : null
                }
                placeholder={handleTruncateText(t("Enter_Location"), limit)}
              />
            </div>
          </div>
        </div>
        <div className="langbox d-none d-md-block d-xl-block col-md-auto">
          {l && (
            <Select
              options={fOptions}
              classNamePrefix={"selectbox"}
              onChange={HandleLanguage}
              defaultValue={
                selectedLang === null && l === "MS"
                  ? options[0]
                  : selectedLang
                  ? options[
                      options.findIndex((event) => event.value === selectedLang)
                    ]
                  : options[
                      options.findIndex(
                        (event) => event.value === l.toLowerCase()
                      )
                    ]
              }
            />
          )}
        </div>
        <div className="col-sm-auto d-xl-none d-flex justify-content-end align-items-center">
          <div className="me-4">
            <FaBars
              size={20}
              onClick={() => setShow((show) => !show)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Sidemenudrawer
            show={show}
            token={token}
            HandleLanguage={HandleLanguage}
            setchanSearch={(data) => setchanSearch(data)}
            chanSearch={chanSearch}
            // selectedLang={selectedLang}
            setSearch={(e) => setSearch(e)}
            handleSearch={(e) => handleSearch(e)}
            handleShowCreate={() => handleShowCreate()}
            handleLogout={() => handleLogout()}
            handleShowLogin={() => handleShowLogin()}
            options={fOptions}
            search={search}
            all={options}
          />
        </div>
      </div>
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
        lng={lng}
        lat={lat}
      />
      {search.length > 2 && (
        <Search
          show={showsearch}
          close={handleCloseSearch}
          setSearch={() => setSearch("")}
          searchBy={chanSearch}
        />
      )}
      {showCreate && userData?.isAuthenticated === true && (
        <NewEvent show={showCreate} close={handleCloseCreate} />
      )}
    </div>
  );
}

export default Header;
