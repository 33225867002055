import httpService from "../../common/http.service";
import { eventAction } from "../slice/eventSlice";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { fileAction } from "../slice/fileSlice";

export const popularEventApi = (data) => async (dispatch) => {
  try {
    const response = await httpService.post("socialevents/popular", {}, data);
    dispatch(eventAction.popularEvent(response.data));
  } catch (err) {
    throw new Error(err);
  }
};
export const nearByEventApi = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `socialevents?eventStatus=${data.eventStatus}&nearBy=${data.nearBy}&lat=${data.lat}&long=${data.long}&distance=${data.distance}&language=${data.language}`,
      {},
      {}
    );
    dispatch(eventAction.nearByEvent(response?.data?.data?.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const findEventApi = (query) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `socialevents/find?name=${query.searchQuery}&language=${query.language}`
    );
    dispatch(eventAction.findEvent(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const eventByIdApi = (query) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `socialevents/${query.eventId}?userId=${query.userId}`
    );
    dispatch(eventAction.eventById(response.data));
  } catch (err) {
    throw new Error(err);
  }
};
export const categoriesApi = () => async (dispatch) => {
  const language = localStorage.getItem("REACT_APP_LANGUAGE");
  try {
    const response = await httpService.get(
      `categories?language=${language}`,
      {},
      {}
    );
    dispatch(eventAction.categories(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const createEventApi = (data) => async (dispatch) => {
  try {
    const response = await httpService.post("socialevents", {}, data);
    dispatch(eventAction.createEvent(response.data));
    toast.success("Your event has been created successfully");
  } catch (err) {
    throw new Error(err);
  }
};

export const getEventListApi = (payload) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `socialevents?searchKey=usersId&searchValue=${payload.userId}&eventStatus=${payload.eventStatus}`
    );
    dispatch(eventAction.events(response?.data));
  } catch (err) {
    throw new Error(err);
  }
};
export const getCreatedEventApi = (payload) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `socialevents?language=${payload?.language}&eventStatus=${payload.eventStatus}&categoryId=${payload.categoryId}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}`
    );
    dispatch(eventAction.eventsAll(response?.data?.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getEventsCategoryList = (payload) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `categories?language=${payload?.language}`
    );
    dispatch(eventAction.setCategoryList(response?.data));
  } catch (error) {
    throw new Error(error);
  }
};

export const UpdateEventApi = (data) => async (dispatch) => {
  try {
    const response = await httpService.put(
      `socialevents?id=${data.eventId}`,
      {},
      data
    );
    dispatch(eventAction.update_event(response?.data));
    dispatch(fileAction.clearFileData());
    dispatch(fileAction.clearImageData());
    toast.success("Your event has been updated successfully");
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteEventApi = (id) => async (dispatch) => {
  try {
    const response = await httpService.delete(`socialevents?id=${id}`);
    if (response?.data) {
      dispatch(eventAction.deleteEvent(id));
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getShareEmails = () => async (dispatch) => {
  try {
    const response = await fetch(
      "https://content-people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses&pageSize=999",
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        scope: [
          "profile",
          "email",
          "https://www.googleapis.com/auth/contacts.readonly",
          "https://www.googleapis.com/auth/contacts",
        ],
      }
    ).then((data) => {
      return data.json();
    });
    dispatch(eventAction.getShareContact(response?.otherContacts));
  } catch (error) {
    toast.error(error);
  }
};

export const showPopular = (state) => state?.event?.popular;
export const showNearBy = (state) => state?.event?.nearBy;
export const showId = (state) => state?.event?.eventId;
export const showSearch = (state) => state?.event?.search;
export const showCategories = (state) => state?.event?.categories;
export const showCreate = (state) => state?.event?.create;
export const showEvents = (state) => state?.event?.events;
export const showEventIdData = (state) => state?.event?.eventIdData;
