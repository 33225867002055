import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./Horoscope.scss";
const MyVerticallyCenteredModal = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className={`p-3 p-md-5 p-lg-5 rounded-3 ${
          (props.modalDataKey === "Prediction" && "bg-1") ||
          (props.modalDataKey === "Monetary Gains" && "bg-2") ||
          (props.modalDataKey === "Love Life" && "bg-3") ||
          (props.modalDataKey === "Health" && "bg-4") ||
          (props.modalDataKey === "Lucky Numbers" && "bg-5") ||
          (props.modalDataKey === "Lucky Colour" && "bg-6")
        }`}
      >
        <h6
          className="route-heading"
          style={{
            fontStyle: "italic",
            fontSize: "5vw",
          }}
        >
          {t(`${props.modalDataKey}`)}
        </h6>
        <p className="montserrat-font">
          <span>{props.modaldata}</span>
        </p>
      </div>
    </Modal>
  );
};

const HoroscopeDetailModal = ({
  modalShow,
  setModalShow,
  modaldata,
  modalDataKey,
}) => {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        modaldata={modaldata}
        modalDataKey={modalDataKey}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default HoroscopeDetailModal;
